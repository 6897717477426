import { useGlobalMetadata, useUpdateGlobalMetadata } from '@/core/api';
import { useAuth } from '@/core/auth';
import { TabNavPortal } from '@/shared/components/layout';
import type { FormSchema } from '@/shared/components/metadata';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  formSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/shared/components/metadata';
import { Button, Card, CardContent, CardDescription, CardHeader, CardTitle, Form } from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { Save } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

interface ValidationError {
  error: unknown;
  metadata: FormSchema['metadata'];
  jsonSchema: JsonSchemaType;
  form: ReturnType<typeof useForm<FormSchema>>;
}

export function ManageOrderMetadata() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const queryClient = useQueryClient();
  const { data: globalMetadata, isLoading } = useGlobalMetadata();
  const updateGlobalMetadata = useUpdateGlobalMetadata();
  const [isUpdating, setIsUpdating] = useState(false);
  const hasValidationErrors = useRef(false);

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const rootOrderForm = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      metadata: globalMetadata ? jsonSchemaToFormFields(globalMetadata.rootOrderMetadata as JsonSchemaType) : [],
    },
  });

  const articleRentalForm = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      metadata: globalMetadata
        ? jsonSchemaToFormFields(globalMetadata.articleRentalOrderMetadata as JsonSchemaType)
        : [],
    },
  });

  const deploymentPlanningForm = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      metadata: globalMetadata
        ? jsonSchemaToFormFields(globalMetadata.deploymentPlanningMetadata as JsonSchemaType)
        : [],
    },
  });

  useEffect(() => {
    if (globalMetadata && !isLoading && !hasValidationErrors.current) {
      rootOrderForm.reset({
        metadata: jsonSchemaToFormFields(globalMetadata.rootOrderMetadata as JsonSchemaType),
      });
      articleRentalForm.reset({
        metadata: jsonSchemaToFormFields(globalMetadata.articleRentalOrderMetadata as JsonSchemaType),
      });
      deploymentPlanningForm.reset({
        metadata: jsonSchemaToFormFields(globalMetadata.deploymentPlanningMetadata as JsonSchemaType),
      });
    }
  }, [globalMetadata, isLoading, rootOrderForm, articleRentalForm, deploymentPlanningForm]);

  const handleSubmit = async () => {
    if (!isJuhAdmin) return;

    const rootOrderData = rootOrderForm.getValues();
    const articleRentalData = articleRentalForm.getValues();
    const deploymentPlanningData = deploymentPlanningForm.getValues();

    const rootJsonSchema = formFieldsToJsonSchema(rootOrderData.metadata);
    const articleRentalJsonSchema = formFieldsToJsonSchema(articleRentalData.metadata);
    const deploymentPlanningJsonSchema = formFieldsToJsonSchema(deploymentPlanningData.metadata);

    const validationErrors: ValidationError[] = [];
    setIsUpdating(true);
    hasValidationErrors.current = false;

    try {
      await updateGlobalMetadata.mutateAsync({
        rootOrderMetadata: rootJsonSchema,
      });
    } catch (error) {
      validationErrors.push({
        error,
        metadata: rootOrderData.metadata,
        jsonSchema: rootJsonSchema,
        form: rootOrderForm,
      });
    }

    try {
      await updateGlobalMetadata.mutateAsync({
        articleRentalOrderMetadata: articleRentalJsonSchema,
      });
    } catch (error) {
      validationErrors.push({
        error,
        metadata: articleRentalData.metadata,
        jsonSchema: articleRentalJsonSchema,
        form: articleRentalForm,
      });
    }

    try {
      await updateGlobalMetadata.mutateAsync({
        deploymentPlanningMetadata: deploymentPlanningJsonSchema,
      });
    } catch (error) {
      validationErrors.push({
        error,
        metadata: deploymentPlanningData.metadata,
        jsonSchema: deploymentPlanningJsonSchema,
        form: deploymentPlanningForm,
      });
    }

    setIsUpdating(false);

    if (validationErrors.length > 0) {
      hasValidationErrors.current = true;
      validationErrors.forEach(({ error, metadata, jsonSchema, form }) => {
        handleMetadataValidationErrors(error, metadata, jsonSchema, form, t);
      });
    } else {
      await queryClient.invalidateQueries({ queryKey: ['globalMetadata'] });
      toast.success(t('metadata.orderMetadataUpdatedSuccessfully'));
    }
  };

  return (
    <div className="space-y-6">
      <TabNavPortal>
        <Button onClick={handleSubmit} disabled={!isJuhAdmin || isUpdating}>
          <Save className="size-4 mr-2" />
          {isUpdating ? t('common.saving') : t('common.save')}
        </Button>
      </TabNavPortal>

      <div className="space-y-6">
        <Form {...rootOrderForm}>
          <Card>
            <CardHeader>
              <CardTitle>{t('metadata.rootOrderMetadata')}</CardTitle>
              <CardDescription>{t('metadata.rootOrderMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <MetadataBuilder disabled={!isJuhAdmin} loading={isLoading} />
            </CardContent>
          </Card>
        </Form>

        <Form {...articleRentalForm}>
          <Card>
            <CardHeader>
              <CardTitle>{t('metadata.articleRentalOrderMetadata')}</CardTitle>
              <CardDescription>{t('metadata.articleRentalOrderMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <MetadataBuilder disabled={!isJuhAdmin} loading={isLoading} />
            </CardContent>
          </Card>
        </Form>

        <Form {...deploymentPlanningForm}>
          <Card>
            <CardHeader>
              <CardTitle>{t('metadata.deploymentPlanningMetadata')}</CardTitle>
              <CardDescription>{t('metadata.deploymentPlanningMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <MetadataBuilder disabled={!isJuhAdmin} loading={isLoading} />
            </CardContent>
          </Card>
        </Form>
      </div>
    </div>
  );
}
