import type { Object } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useObjects = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Object | `-${keyof Object}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['objects', params],
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'objects',
        ...params,
      });
      return response;
    },
  });
};

export const useObject = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['object', id, params],
    enabled: !params?.disabled && !!id,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'objects',
        id,
      });
      return response;
    },
  });
};

export const useCreateObject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Partial<Object>) => {
      const response = await payloadClient.create({
        collection: 'objects',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['objects'] });
    },
  });
};

export const useUpdateObject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Object> }) => {
      const response = await payloadClient.updateById({
        collection: 'objects',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['objects'] });
    },
  });
};

export const useDeleteObject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'objects',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['objects'] });
    },
  });
};
