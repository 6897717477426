import { DataTablePagination, TableLoadingCell } from '@/shared/components/tables';
import { EmptyTableRow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/components/ui';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/components/ui/alert-dialog';
import type { MetadataGroup } from '@johanniter-offshore/backend';
import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';

import { MetadataGroupTableActions } from './metadata-group-table-actions';

interface MetadataGroupTableProps {
  groups: MetadataGroup[];
  onEdit: (group: MetadataGroup) => void;
  onDelete: (group: MetadataGroup) => void;
  isLoading?: boolean;
  pagination?: {
    pageCount: number;
    state: PaginationState;
    onPaginationChange: (value: PaginationState) => void;
  };
  isActionDisabled?: boolean;
}

export const MetadataGroupTable = ({
  groups,
  onEdit,
  onDelete,
  isLoading,
  pagination,
  isActionDisabled,
}: MetadataGroupTableProps) => {
  const { t } = useIntl();
  const [groupToDelete, setGroupToDelete] = useState<MetadataGroup | null>(null);

  const columns: ColumnDef<MetadataGroup>[] = [
    {
      accessorKey: 'name',
      header: t('metadata.groups.groupName'),
    },
    {
      id: 'actions',
      header: () => <span className="sr-only">{t('common.actions')}</span>,
      cell: ({ row }) => (
        <MetadataGroupTableActions
          onEdit={() => onEdit(row.original)}
          onDelete={() => setGroupToDelete(row.original)}
          disabled={isActionDisabled}
        />
      ),
    },
  ];

  const table = useReactTable({
    data: groups,
    columns,
    pageCount: pagination?.pageCount ?? 1,
    state: {
      pagination: pagination?.state,
    },
    onPaginationChange: (updaterOrValue) => {
      if (pagination?.onPaginationChange) {
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(pagination.state) : updaterOrValue;
        pagination.onPaginationChange(newValue);
      }
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="space-y-4">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className={header.column.id === 'actions' ? 'text-right' : undefined}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableLoadingCell colSpan={2} />
            ) : groups.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={cell.column.id === 'actions' ? 'text-right' : undefined}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <EmptyTableRow />
            )}
          </TableBody>
        </Table>
        {pagination && <DataTablePagination table={table} />}
      </div>

      <AlertDialog open={!!groupToDelete} onOpenChange={() => setGroupToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('metadata.groups.deleteGroup')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('metadata.groups.deleteGroupConfirmation', { name: groupToDelete?.name ?? '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (groupToDelete) {
                  onDelete(groupToDelete);
                  setGroupToDelete(null);
                }
              }}
            >
              {t('common.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
