import { useArticle, useArticleVersions, useOrder, useResolvedTemplateMetadata, useTemplate } from '@/core/api';
import type { ActivityItem } from '@/shared/components/activity';
import {
  ActivityTimeline,
  ResolvedUser,
  transformVersionsToActivity,
} from '@/shared/components/activity/activity-timeline';
import type { Article, JsonSchemaType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

function getChanges(currentVersion: Article, previousVersion: Article): ActivityItem['changes'] {
  const changes: ActivityItem['changes'] = [];

  // Track expiry date changes
  if (currentVersion.expiryDate !== previousVersion.expiryDate) {
    changes.push({
      field: 'expiryDate',
      from: previousVersion.expiryDate,
      to: currentVersion.expiryDate,
    });
  }

  // Track order assignment changes
  if (currentVersion.order !== previousVersion.order) {
    changes.push({
      field: 'order',
      from: previousVersion.order,
      to: currentVersion.order,
    });
  }

  // Track parent changes
  if (currentVersion.parent !== previousVersion.parent) {
    changes.push({
      field: 'parent',
      from: previousVersion.parent,
      to: currentVersion.parent,
    });
  }

  // Track metadata changes
  const currentMetadata = (currentVersion.metadata || {}) as Record<string, unknown>;
  const previousMetadata = (previousVersion.metadata || {}) as Record<string, unknown>;

  // Get all unique keys from metadata objects
  const metadataKeys = new Set([...Object.keys(currentMetadata), ...Object.keys(previousMetadata)]);

  metadataKeys.forEach((key) => {
    const currentValue = currentMetadata[key];
    const previousValue = previousMetadata[key];

    if (currentValue !== previousValue) {
      changes.push({
        field: `metadata.${key}`,
        from: previousValue,
        to: currentValue,
      });
    }
  });

  // Track article files changes
  const currentFiles = currentVersion.articleFiles || [];
  const previousFiles = previousVersion.articleFiles || [];

  // Track added files (new files or restored files)
  currentFiles.forEach((file) => {
    const previousFile = previousFiles.find((pf) => pf.id === file.id);
    const isFilePresent = file.file !== null;
    const wasFilePresent = previousFile?.file !== null;

    if (!previousFile || (!wasFilePresent && isFilePresent)) {
      changes.push({
        field: 'articleFiles.added',
        to: file.name,
      });
    }
  });

  // Track removed files (deleted files or removed entries)
  previousFiles.forEach((file) => {
    const currentFile = currentFiles.find((cf) => cf.id === file.id);
    const wasFilePresent = file.file !== null;
    const isFilePresent = currentFile?.file !== null;

    if (!currentFile || (wasFilePresent && !isFilePresent)) {
      changes.push({
        field: 'articleFiles.removed',
        from: file.name,
      });
    }
  });

  // If no changes were detected, add a special "no changes" item
  if (changes.length === 0) {
    changes.push({
      field: 'noChanges',
    });
  }

  return changes;
}

function getFieldName(field: string, metadataSchema?: JsonSchemaType, t?: (key: string) => string) {
  if (field === 'noChanges') {
    return t ? t('activity.fields.noChanges') : 'activity.fields.noChanges';
  }
  if (!field.startsWith('metadata.')) {
    return t ? t(`activity.fields.${field}`) : `activity.fields.${field}`;
  }
  const [, key] = field.split('.');
  if (!metadataSchema?.properties[key]) {
    return null;
  }
  const description = metadataSchema.properties[key]?.description;
  if (description) {
    return description;
  }
  return t ? t(`activity.fields.${field}`) : `activity.fields.${field}`;
}

function ResolvedFieldValue({
  field,
  value,
  metadataSchema,
}: {
  field: string;
  value: unknown;
  metadataSchema?: JsonSchemaType;
}) {
  const { t } = useIntl();
  // Always call hooks unconditionally to preserve hook invocation order.
  const { data: order } = useOrder(field === 'order' && typeof value === 'string' ? value : '', {
    disabled: field !== 'order' || typeof value !== 'string',
  });
  const { data: parentArticle } = useArticle(field === 'parent' && typeof value === 'string' ? value : '', {
    disabled: field !== 'parent' || typeof value !== 'string',
  });

  // Special handling for "no changes" case
  if (field === 'noChanges') {
    return <span className="text-muted-foreground">{t('activity.noChanges')}</span>;
  }

  if (value === null || value === undefined || value === '') {
    return <span className="font-medium">{t('activity.none')}</span>;
  }

  if (field === 'expiryDate') {
    const date = DateTime.fromISO(value as string);
    if (date.isValid) {
      return <span>{date.toFormat('dd.MM.yyyy')}</span>;
    }
  }

  if (field === 'order' && order) {
    return <span>{order.title}</span>;
  }

  if (field === 'parent' && parentArticle) {
    return <span>{parentArticle.id}</span>;
  }

  // Handle metadata fields
  if (field.startsWith('metadata.') && metadataSchema) {
    const [, key] = field.split('.');
    const fieldDef = metadataSchema.properties[key];

    if (fieldDef) {
      switch (fieldDef.type) {
        case 'date':
          return typeof value === 'string' && DateTime.fromISO(value).isValid ? (
            <span>{DateTime.fromISO(value).toFormat('dd.MM.yyyy')}</span>
          ) : (
            <span>{String(value)}</span>
          );
        case 'boolean':
          return <span>{t(value ? 'common.yes' : 'common.no')}</span>;
        case 'number':
          return <span>{typeof value === 'number' ? value.toString() : String(value)}</span>;
        default:
          return <span>{String(value)}</span>;
      }
    }
  }

  // Handle article files
  if (field === 'articleFiles.added') {
    return (
      <span className="text-muted-foreground">
        {t('activity.fileAdded')} <span className="font-medium text-foreground">{`"${String(value)}"`}</span>
      </span>
    );
  }
  if (field === 'articleFiles.removed') {
    return (
      <span className="text-muted-foreground">
        {t('activity.fileRemoved')} <span className="font-medium text-foreground">{`"${String(value)}"`}</span>
      </span>
    );
  }

  return <span>{String(value)}</span>;
}

interface ArticleActivityProps {
  articleId?: string;
}

export function ArticleActivity({ articleId }: ArticleActivityProps) {
  const { t, locale } = useIntl();
  const {
    data: versionsData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useArticleVersions(articleId!, {
    disabled: !articleId,
    limit: 15,
  });

  const { data: article } = useArticle(articleId!);
  const { data: template } = useTemplate(article?.template as string);
  const { data: metadataSchema } = useResolvedTemplateMetadata(template?.id);

  if (isLoading) {
    return <div className="text-center text-muted-foreground">{t('activity.loading')}</div>;
  }

  if (!versionsData?.pages[0].docs.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  // Flatten all pages of activities
  const allDocs = versionsData.pages.flatMap((page) => page.docs);
  const allActivities = transformVersionsToActivity(allDocs, getChanges);

  const activities = allActivities.filter((activity) => {
    if (activity.type === 'created') return !hasNextPage;
    return activity.changes?.some((change) => {
      const fieldName = getFieldName(change.field, metadataSchema, t);
      return fieldName !== null;
    });
  });

  if (!activities.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  return (
    <div className="space-y-4">
      <ActivityTimeline
        activities={activities}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
      >
        {(activity) =>
          activity.type === 'created' ? (
            <div className="flex items-center gap-1">
              <ResolvedUser userId={activity.user!} />
              <span className="text-muted-foreground">{t('activity.created')}</span>
              <span className="text-muted-foreground">
                · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
              </span>
            </div>
          ) : (
            activity.changes?.map((change, index) => {
              // Special handling for "no changes" case
              if (change.field === 'noChanges') {
                return (
                  <div key={index} className="flex items-center gap-1">
                    <ResolvedUser userId={activity.user!} />
                    <span className="text-muted-foreground">{t('activity.savedWithoutChanges')}</span>
                    <span className="text-muted-foreground">
                      · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                    </span>
                  </div>
                );
              }

              const fieldName = getFieldName(change.field, metadataSchema, t);
              if (fieldName === null) return null;

              // Special handling for file events
              if (change.field === 'articleFiles.added' || change.field === 'articleFiles.removed') {
                return (
                  <div key={index} className="flex items-center gap-1">
                    <ResolvedUser userId={activity.user!} />
                    <ResolvedFieldValue
                      field={change.field}
                      value={change.field === 'articleFiles.added' ? change.to : change.from}
                      metadataSchema={metadataSchema}
                    />
                    <span className="text-muted-foreground">
                      · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                    </span>
                  </div>
                );
              }

              // Regular field changes
              return (
                <div key={index} className="flex items-center gap-1">
                  <ResolvedUser userId={activity.user!} />
                  <span className="text-muted-foreground">{t('activity.changed')}</span>
                  {change.field.startsWith('metadata.') ? (
                    <span>
                      <span className="text-muted-foreground">{t('activity.metadataField')}</span>
                      <span className="font-medium"> {fieldName}</span>
                    </span>
                  ) : (
                    <span className="font-medium">{fieldName}</span>
                  )}
                  <span className="text-muted-foreground">{t('activity.from')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={change.field} value={change.from} metadataSchema={metadataSchema} />
                  </span>
                  <span className="text-muted-foreground">{t('activity.to')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={change.field} value={change.to} metadataSchema={metadataSchema} />
                  </span>
                  <span className="text-muted-foreground">
                    · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                  </span>
                </div>
              );
            })
          )
        }
      </ActivityTimeline>
    </div>
  );
}
