import { SearchCombobox } from '@/shared/components/inputs/search-combobox';
import {
  Button,
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import { type FieldType, type JsonSchemaType, fieldType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { ArrowDown, ArrowUp, Eye, EyeOff, Pencil, Plus, Trash } from 'lucide-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

export const metadataFieldSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  type: z.enum(fieldType),
  required: z.boolean(),
  customerAccess: z.enum(['none', 'read', 'read-and-write']).default('read'),
  group: z.string().nullable(),
});

export type MetadataFormField = z.infer<typeof metadataFieldSchema>;

export const formSchema = z.object({
  metadata: z.array(metadataFieldSchema),
});

export type FormSchema = z.infer<typeof formSchema>;

const newField = (type: FieldType = 'string'): MetadataFormField => ({
  key: `tempkey-${Date.now()}`,
  label: '',
  type,
  required: false,
  customerAccess: 'read',
  group: null,
});

const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z0-9]/g, '');
};

interface MetadataBuilderProps {
  disabled?: boolean;
  className?: string;
  loading?: boolean;
}

export const jsonSchemaToFormFields = (jsonSchema: JsonSchemaType): MetadataFormField[] => {
  return jsonSchema.propertyOrder.map((key) => ({
    key,
    label: jsonSchema.properties[key].description,
    type:
      jsonSchema.properties[key].type === 'string' && jsonSchema.properties[key].format === 'date-time'
        ? 'date'
        : jsonSchema.properties[key].type,
    required: jsonSchema.required.includes(key),
    customerAccess: (jsonSchema.properties[key].customerAccess as 'none' | 'read' | 'read-and-write') || 'read',
    group: (jsonSchema.properties[key].group as string | null) || null,
  }));
};

export const formFieldsToJsonSchema = (fields: MetadataFormField[]): JsonSchemaType => {
  const properties: JsonSchemaType['properties'] = {};
  const required: string[] = [];
  const propertyOrder: string[] = [];

  fields.forEach((field) => {
    let key = field.key;
    if (key.startsWith('tempkey-')) {
      key = `${toCamelCase(field.label)}-${nanoid()}`;
    }
    properties[key] =
      field.type === 'date'
        ? {
            type: 'string',
            format: 'date-time',
            description: field.label,
            customerAccess: field.customerAccess,
            group: field.group,
          }
        : field.type === 'string'
          ? {
              type: field.type,
              description: field.label,
              customerAccess: field.customerAccess,
              group: field.group,
              ...(field.required && { minLength: 1 }),
            }
          : {
              type: field.type,
              description: field.label,
              customerAccess: field.customerAccess,
              group: field.group,
            };
    if (field.required) {
      required.push(key);
    }
    propertyOrder.push(key);
  });

  return {
    type: 'object',
    properties,
    required,
    propertyOrder,
  };
};

export function handleMetadataValidationErrors(
  error: unknown,
  metadata: FormSchema['metadata'],
  jsonSchema: JsonSchemaType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>,
  t: (key: string) => string,
) {
  const payloadError = error as {
    statusCode?: number;
    response?: {
      errors?: Array<{
        name: string;
        data?: {
          errors?: Array<{
            code: string;
            field: string;
            message: string;
            details?: { fields: string[] };
          }>;
        };
        message: string;
      }>;
    };
    name: string;
  };

  if (payloadError.response?.errors) {
    payloadError.response.errors.forEach((err) => {
      if (err.name === 'APIError' && err.data?.errors) {
        err.data.errors.forEach((apiError) => {
          switch (apiError.code) {
            case 'NewRequiredFieldAdditionNotAllowed':
            case 'ExistingFieldMadeRequiredWithMissingValues':
              apiError.details?.fields.forEach((field) => {
                const fieldIndex = metadata.findIndex((metadataField) => {
                  return (
                    metadataField.key === field ||
                    (metadataField.key.startsWith('tempkey-') &&
                      jsonSchema.propertyOrder.includes(field) &&
                      jsonSchema.properties[field].description === metadataField.label)
                  );
                });

                if (fieldIndex !== -1) {
                  form.setError(`metadata.${fieldIndex}.required`, {
                    type: 'manual',
                    message: t('metadata.fieldMustBeOptional'),
                  });
                }
              });
              break;
            case 'FieldTypeChangeNotAllowed': {
              const fieldIndex = metadata.findIndex(
                (metadataField) =>
                  metadataField.key === apiError.field ||
                  (metadataField.key.startsWith('tempkey-') &&
                    jsonSchema.propertyOrder.includes(apiError.field) &&
                    jsonSchema.properties[apiError.field].description === metadataField.label),
              );
              if (fieldIndex !== -1) {
                form.setError(`metadata.${fieldIndex}.type`, {
                  type: 'manual',
                  message: t('metadata.fieldTypeChangeNotAllowed'),
                });
              }
              break;
            }
            default:
              toast.error(t('metadata.metadataUpdateFailed'), {
                description: apiError.message,
              });
          }
        });
      } else {
        toast.error(t('errors.unknownError'), {
          description: err.message,
        });
      }
    });
  } else if (payloadError.name === 'PayloadApiError') {
    toast.error(t('metadata.metadataUpdateFailed'), {
      description: payloadError.response?.errors?.[0]?.message || t('errors.unknownError'),
    });
  } else {
    toast.error(t('errors.unknownError'));
  }
}

export function MetadataBuilder({ disabled = false, className, loading = false }: MetadataBuilderProps) {
  const { t } = useIntl();
  const { control } = useFormContext<FormSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'metadata',
  });

  const fieldTypes: { value: FieldType; label: string }[] = [
    { value: 'string', label: t('metadata.fieldTypes.string') },
    { value: 'number', label: t('metadata.fieldTypes.number') },
    { value: 'boolean', label: t('metadata.fieldTypes.boolean') },
    { value: 'date', label: t('metadata.fieldTypes.date') },
  ];

  const customerAccessTypes = [
    { value: 'none', label: t('metadata.customerAccess.none'), icon: EyeOff },
    { value: 'read', label: t('metadata.customerAccess.read'), icon: Eye },
    { value: 'read-and-write', label: t('metadata.customerAccess.readAndWrite'), icon: Pencil },
  ];

  return (
    <div className={cn('rounded-lg', className)}>
      <Table className="border-b">
        <TableHeader>
          <TableRow>
            <TableHead className="w-[120px] pl-5">{t('common.move')}</TableHead>
            <TableHead>{t('common.label')}</TableHead>
            <TableHead className="w-[185px]">{t('common.type')}</TableHead>
            <TableHead className="w-[50px]">{t('common.required')}</TableHead>
            <TableHead className="w-[185px]">{t('metadata.customerAccess.label')}</TableHead>
            <TableHead className="w-[185px]">{t('metadata.groups.label')}</TableHead>
            <TableHead className="w-[50px] pr-5">{t('common.delete')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={7} className="h-[49px] text-center">
                <div className="flex items-center justify-center gap-2">
                  <div className="size-3 border-2 border-muted-foreground/30 border-t-muted-foreground animate-spin rounded-full" />
                  <span className="text-muted-foreground">{t('common.loading')}</span>
                </div>
              </TableCell>
            </TableRow>
          ) : (
            fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <TableRow>
                  <TableCell className="w-[120px] align-top pl-5">
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => move(index, Math.max(0, index - 1))}
                      disabled={disabled || index === 0}
                      className="size-8"
                      type="button"
                      aria-label={t('common.moveUp')}
                    >
                      <ArrowUp className="size-4" />
                    </Button>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => move(index, Math.min(fields.length - 1, index + 1))}
                      disabled={disabled || index === fields.length - 1}
                      className="size-8"
                      type="button"
                      aria-label={t('common.moveDown')}
                    >
                      <ArrowDown className="size-4" />
                    </Button>
                  </TableCell>
                  <TableCell className="align-top">
                    <FormField
                      control={control}
                      name={`metadata.${index}.label`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input {...field} className="h-8" disabled={disabled} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="w-[150px] align-top">
                    <FormField
                      control={control}
                      name={`metadata.${index}.type`}
                      render={({ field }) => (
                        <FormItem>
                          <Select onValueChange={field.onChange} defaultValue={field.value} disabled={disabled}>
                            <FormControl>
                              <SelectTrigger className="h-8 w-[150px]">
                                <SelectValue placeholder={t('metadata.fieldTypes.selectFieldType')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {fieldTypes.map((type) => (
                                <SelectItem key={type.value} value={type.value}>
                                  {type.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="w-[150px] align-top">
                    <FormField
                      control={control}
                      name={`metadata.${index}.required`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="flex size-8 items-center justify-center">
                              <Checkbox
                                className="data-[state=checked]:text-background data-[state=checked]:bg-foreground  border-inherit"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                disabled={disabled}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="w-[220px] align-top">
                    <FormField
                      control={control}
                      name={`metadata.${index}.customerAccess`}
                      render={({ field }) => (
                        <FormItem>
                          <Select onValueChange={field.onChange} defaultValue={field.value} disabled={disabled}>
                            <FormControl>
                              <SelectTrigger className="h-8 w-[220px]">
                                <SelectValue placeholder={t('metadata.customerAccess.selectAccess')}>
                                  {field.value && (
                                    <div className="flex items-center gap-2">
                                      {React.createElement(
                                        customerAccessTypes.find((type) => type.value === field.value)?.icon ?? Eye,
                                        { className: 'size-4' },
                                      )}
                                      <span>
                                        {customerAccessTypes.find((type) => type.value === field.value)?.label}
                                      </span>
                                    </div>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {customerAccessTypes.map((type) => (
                                <SelectItem key={type.value} value={type.value}>
                                  <div className="flex items-center gap-2">
                                    {React.createElement(type.icon, { className: 'size-4' })}
                                    <span>{type.label}</span>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="align-top w-[280px]">
                    <FormField
                      control={control}
                      name={`metadata.${index}.group`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <SearchCombobox
                              value={field.value || undefined}
                              onSelect={field.onChange}
                              collectionKey="metadata-groups"
                              searchKey="name"
                              className="h-8"
                              texts={{
                                selectItemMsg: t('metadata.groups.selectGroup'),
                              }}
                              clearValue={null}
                              disabled={disabled}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>
                  <TableCell className="align-top pr-5">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => remove(index)}
                      className="size-8"
                      type="button"
                      aria-label={t('common.delete')}
                      disabled={disabled}
                    >
                      <Trash className="size-4 " />
                    </Button>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
      {!loading && (
        <div className="flex">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => append(newField())}
            type="button"
            className={cn('w-full rounded-none rounded-b text-muted-foreground hover:text-foreground')}
            aria-label={t('metadata.addField')}
            disabled={disabled}
          >
            <Plus className="size-4" />
          </Button>
        </div>
      )}
    </div>
  );
}
