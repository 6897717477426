import type { GlobalMetadatum } from '@johanniter-offshore/backend';
import { useMutation, useQuery } from '@tanstack/react-query';

import { payloadClient } from '.';

export const useGlobalMetadata = () => {
  return useQuery({
    queryKey: ['globalMetadata'],
    queryFn: async () => {
      const response = await payloadClient.findGlobal({
        slug: 'global-metadata',
      });
      return response as GlobalMetadatum;
    },
  });
};

export const useUpdateGlobalMetadata = () => {
  return useMutation({
    mutationFn: async (data: Partial<GlobalMetadatum>) => {
      const response = await payloadClient.updateGlobal({
        slug: 'global-metadata',
        data,
      });
      return response as GlobalMetadatum;
    },
  });
};
