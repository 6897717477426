import { useAuth } from '@/core/auth';
import { useIntl } from '@tiny-intl/react';
import { useLocation } from 'react-router-dom';

import { LoginForm } from '../components/login-form';

export function Login() {
  const { t } = useIntl();
  const { login, loading: isLoading } = useAuth();
  const location = useLocation();

  // Get email from URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get('email') || '';

  const defaultEmail = emailFromQuery || (import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_EMAIL || '' : '');
  const defaultPassword = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_PASSWORD || '' : '';

  async function handleLogin(values: { email: string; password: string }) {
    await login({
      email: values.email,
      password: values.password,
    });
  }

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.login')}</h1>
        <p className="text-muted-foreground text-balance">{t('auth.loginDescription')}</p>
      </div>
      <LoginForm
        onSubmit={handleLogin}
        isLoading={isLoading}
        defaultEmail={defaultEmail}
        defaultPassword={defaultPassword}
      />
    </div>
  );
}
