import { DataTablePagination, TableLoadingCell } from '@/shared/components/tables';
import { EmptyTableRow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/components/ui';
import type { Object } from '@johanniter-offshore/backend';
import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';

import { ObjectTableActions } from './object-table-actions';

interface ObjectTableProps {
  objects: Object[];
  onEdit: (object: Object) => void;
  onDelete: (object: Object) => void;
  isLoading?: boolean;
  pagination?: {
    pageCount: number;
    state: PaginationState;
    onPaginationChange: (value: PaginationState) => void;
  };
}

export const ObjectTable = ({ objects, onEdit, onDelete, isLoading, pagination }: ObjectTableProps) => {
  const { t } = useIntl();

  const columns: ColumnDef<Object>[] = [
    {
      accessorKey: 'name',
      header: t('common.name'),
    },
    {
      accessorKey: 'description',
      header: t('common.description'),
    },
    {
      id: 'actions',
      header: () => <span className="sr-only">{t('common.actions')}</span>,
      cell: ({ row }) => (
        <ObjectTableActions onEdit={() => onEdit(row.original)} onDelete={() => onDelete(row.original)} />
      ),
    },
  ];

  const table = useReactTable({
    data: objects,
    columns,
    pageCount: pagination?.pageCount ?? 1,
    state: {
      pagination: pagination?.state,
    },
    onPaginationChange: (updaterOrValue) => {
      if (pagination?.onPaginationChange) {
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(pagination.state) : updaterOrValue;
        pagination.onPaginationChange(newValue);
      }
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="space-y-4">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className={header.column.id === 'actions' ? 'text-right' : undefined}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableLoadingCell colSpan={3} />
          ) : objects.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={cell.column.id === 'actions' ? 'text-right' : 'truncate'}>
                    {cell.column.id === 'description' ? (
                      <div className="max-w-[200px] truncate">{String(cell.getValue() ?? '')}</div>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyTableRow />
          )}
        </TableBody>
      </Table>
      {pagination && <DataTablePagination table={table} />}
    </div>
  );
};
