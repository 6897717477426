import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { Calendar as CalendarIcon, X } from 'lucide-react';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date | null;
    setDate: (date?: Date | null) => void;
    placeholder?: string;
    disabled?: boolean;
    defaultMonth?: Date | null;
  }
>(function DatePickerCmp({ date, setDate, placeholder, disabled = false, defaultMonth }, ref) {
  const { locale } = useIntl();

  return (
    <div className="relative flex w-full">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn('w-full justify-start px-3 text-left font-normal', !date && 'text-muted-foreground')}
            disabled={disabled}
          >
            <CalendarIcon className="size-4" />
            {date ? (
              DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED, {
                locale,
              })
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" ref={ref}>
          <Calendar
            mode="single"
            selected={date || undefined}
            onSelect={setDate}
            initialFocus
            defaultMonth={defaultMonth || undefined}
          />
        </PopoverContent>
      </Popover>
      {date && (
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-0 h-full px-2 hover:bg-transparent w-[42px] group"
          onClick={(e) => {
            e.preventDefault();
            setDate(null);
          }}
        >
          <X className="size-4 opacity-50 group-hover:opacity-100" />
        </Button>
      )}
    </div>
  );
});
