import { useOrder } from '@/core/api';
import { useAuth } from '@/core/auth';
import { DataTablePagination } from '@/shared/components/tables';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  EmptyTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useTablePreferences } from '@/shared/services/table-preferences';
import type { Order } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ActionCell = ({ order, onDelete }: { order: Order; onDelete?: (order: Order) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const { id: parentId } = useParams();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';

  const handleEdit = () => {
    navigate(`${basePath}/${parentId}/sub-orders/${order.id}/${order.type}`);
  };

  if (!isJuhUser) {
    return (
      <Button variant="ghost" size="icon" onClick={handleEdit} aria-label={t('common.view')}>
        <ArrowRight className="size-4" />
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={handleEdit}>{t('common.edit')}</DropdownMenuItem>
        {isJuhAdmin && onDelete && (
          <DropdownMenuItem onClick={() => onDelete(order)}>{t('common.delete')}</DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface OrderSubOrdersProps {
  orders: Order[];
  isLoading?: boolean;
  onDelete?: (order: Order) => void;
}

export function OrderSubOrders({ orders, isLoading, onDelete }: OrderSubOrdersProps) {
  const { t } = useIntl();
  const { id: parentId } = useParams();
  const { data: parentOrder } = useOrder(parentId!);

  const tablePreferences = useTablePreferences({
    tableId: 'order-sub-orders',
    defaultPreferences: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting: [],
      columnFilters: [],
      columnVisibility: {},
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();

  const [sorting, setSorting] = useState<SortingState>(preferences.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(preferences.columnFilters || []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(preferences.columnVisibility || {});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState(
    preferences.pagination || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sorting, columnFilters, columnVisibility]);

  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: 'title',
      header: t('orders.title'),
      cell: ({ row }) => <div>{row.getValue('title')}</div>,
    },
    {
      accessorKey: 'orderNumber',
      header: t('orders.orderNumber'),
      cell: ({ row }) => <div>{row.getValue('orderNumber')}</div>,
    },
    {
      accessorKey: 'type',
      header: t('orders.orderType'),
      cell: ({ row }) => {
        const type = row.getValue('type') as string;
        return <Badge>{t(`orders.${type}`)}</Badge>;
      },
    },
    {
      accessorKey: 'startDate',
      header: t('orders.startDate'),
      cell: ({ row }) => {
        const date = row.original.startDate;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'endDate',
      header: t('orders.endDate'),
      cell: ({ row }) => {
        const date = row.original.endDate;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'createdAt',
      header: t('common.createdAt'),
      cell: ({ row }) => {
        const date = row.getValue('createdAt') as string;
        return <div>{DateTime.fromISO(date).toFormat('dd.MM.yyyy')}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell order={row.original} onDelete={onDelete} />,
    },
  ];

  const table = useReactTable({
    data: orders ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (!parentOrder) {
    return null;
  }

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t('common.loading')}
              </TableCell>
            </TableRow>
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyTableRow colSpan={columns.length} />
          )}
        </TableBody>
      </Table>

      <DataTablePagination table={table} />
    </>
  );
}
