import { payloadClient } from '@/core/api';
import { useAuth } from '@/core/auth';
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { ExternalLink } from 'lucide-react';

interface CertificatesButtonProps {
  show?: boolean;
}

// Allowed roles for certificate management
const ALLOWED_ROLES = ['juh-admin', 'juh-employee'];

export function CertificatesButton({ show = false }: CertificatesButtonProps) {
  const { t } = useIntl();
  const { user } = useAuth();

  const isJuhUser = user?.role && ALLOWED_ROLES.includes(user.role);
  const showButton = show && isJuhUser;

  if (!showButton) return null;

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    const { token } = await payloadClient.customRequest<{ token: string }>({
      subpath: '/auth/certificates-management/login',
      method: 'POST',
    });

    window.open(`${import.meta.env.VITE_CERTIFICATE_SERVICE_URL}?token=${token}`, '_blank');
  };

  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild size="sm">
              <a href="/auth/certificates" onClick={handleClick} rel="noopener noreferrer">
                <ExternalLink className="size-4" />
                <span className="flex-1">{t('auth.certificateLogin')}</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
