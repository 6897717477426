import { z } from 'zod';

export const deploymentSchema = z.object({
  id: z.string().nullable(),
  title: z.string().min(1),
  startDate: z.string().min(1),
  endDate: z.string().min(1),
  crewChange: z.string().optional().nullable(),
  description: z.string().optional(),
});

export type DeploymentFormValues = z.infer<typeof deploymentSchema>;

export type DeploymentData = {
  id?: string | null;
  title: string;
  startDate: string;
  endDate: string;
  crewChange?: string | null;
  description?: string | null;
};
