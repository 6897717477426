import type { Config } from '@johanniter-offshore/backend';

import { PayloadApiClient } from './client';

const payloadClient = new PayloadApiClient<Config>({
  apiURL: import.meta.env.VITE_BACKEND_URL || 'http://localhost:3000/api',
  fetcher: (url, init) => {
    return fetch(url, {
      ...(init ?? {}),
    });
  },
});

export { payloadClient };
export * from './client';
export type * from './types';
export * from './articles';
export * from './invites';
export * from './media';
export * from './objects';
export * from './orders';
export * from './organizations';
export * from './categories';
export * from './templates';
export * from './users';
export * from './global-metadata';
export * from './change-requests';
export * from './metadata-groups';
