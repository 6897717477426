import { useObject, useOrganization } from '@/core/api';
import { useGlobalMetadata } from '@/core/api/global-metadata';
import { useOrder, useOrderVersions } from '@/core/api/orders';
import { ActivityTimeline, ResolvedUser, transformVersionsToActivity } from '@/shared/components/activity';
import type { GlobalMetadatum, JsonSchemaType, Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

interface ActivityItem {
  id: string;
  type: 'created' | 'updated';
  timestamp: string;
  user: string | null;
  changes?: {
    field: string;
    from?: unknown;
    to?: unknown;
  }[];
}

function getChanges(currentVersion: Order, previousVersion: Order): ActivityItem['changes'] {
  const changes: ActivityItem['changes'] = [];

  // Track title changes
  if (currentVersion.title !== previousVersion.title) {
    changes.push({
      field: 'title',
      from: previousVersion.title,
      to: currentVersion.title,
    });
  }

  // Track orderNumber changes
  if (currentVersion.orderNumber !== previousVersion.orderNumber) {
    changes.push({
      field: 'orderNumber',
      from: previousVersion.orderNumber,
      to: currentVersion.orderNumber,
    });
  }

  // Track description changes
  if (currentVersion.description !== previousVersion.description) {
    changes.push({
      field: 'description',
      from: previousVersion.description,
      to: currentVersion.description,
    });
  }

  // Track date changes
  if (currentVersion.startDate !== previousVersion.startDate) {
    changes.push({
      field: 'startDate',
      from: previousVersion.startDate,
      to: currentVersion.startDate,
    });
  }

  if (currentVersion.endDate !== previousVersion.endDate) {
    changes.push({
      field: 'endDate',
      from: previousVersion.endDate,
      to: currentVersion.endDate,
    });
  }

  // Track organization changes
  if (currentVersion.organization !== previousVersion.organization) {
    changes.push({
      field: 'organization',
      from: previousVersion.organization,
      to: currentVersion.organization,
    });
  }

  // Track object changes
  if (currentVersion.object !== previousVersion.object) {
    changes.push({
      field: 'object',
      from: previousVersion.object,
      to: currentVersion.object,
    });
  }

  // Track metadata changes
  const currentMetadata = (currentVersion.metadata || {}) as Record<string, unknown>;
  const previousMetadata = (previousVersion.metadata || {}) as Record<string, unknown>;
  const currentDynamicMetadata = (currentVersion.dynamicMetadataValues || {}) as Record<string, unknown>;
  const previousDynamicMetadata = (previousVersion.dynamicMetadataValues || {}) as Record<string, unknown>;

  // Get all unique keys from both metadata objects
  const metadataKeys = new Set([...Object.keys(currentMetadata), ...Object.keys(previousMetadata)]);
  const dynamicMetadataKeys = new Set([
    ...Object.keys(currentDynamicMetadata),
    ...Object.keys(previousDynamicMetadata),
  ]);

  metadataKeys.forEach((key) => {
    const currentValue = currentMetadata[key];
    const previousValue = previousMetadata[key];

    if (currentValue !== previousValue) {
      changes.push({
        field: `metadata.${key}`,
        from: previousValue,
        to: currentValue,
      });
    }
  });

  dynamicMetadataKeys.forEach((key) => {
    const currentValue = currentDynamicMetadata[key];
    const previousValue = previousDynamicMetadata[key];

    if (currentValue !== previousValue) {
      changes.push({
        field: `dynamicMetadata.${key}`,
        from: previousValue,
        to: currentValue,
      });
    }
  });

  // If no changes were detected, add a special "no changes" item
  if (changes.length === 0) {
    changes.push({
      field: 'noChanges',
    });
  }

  return changes;
}

function getFieldName(field: string, order?: Order, globalMetadata?: GlobalMetadatum, t?: (key: string) => string) {
  // Handle "no changes" case
  if (field === 'noChanges') {
    return t ? t('activity.fields.noChanges') : 'activity.fields.noChanges';
  }

  // Handle regular fields
  if (!field.startsWith('metadata.') && !field.startsWith('dynamicMetadata.')) {
    return t ? t(`activity.changes.${field}`) : `activity.changes.${field}`;
  }

  // Handle metadata fields
  const [type, key] = field.split('.');
  let metadataSchema: JsonSchemaType | undefined;

  if (type === 'metadata') {
    // Get the appropriate global metadata schema based on order type
    if (order?.type === 'root-order') {
      metadataSchema = globalMetadata?.rootOrderMetadata;
    } else if (order?.type === 'article-rental') {
      metadataSchema = globalMetadata?.articleRentalOrderMetadata;
    } else if (order?.type === 'deployment-planning') {
      metadataSchema = globalMetadata?.deploymentPlanningMetadata;
    }
  } else if (type === 'dynamicMetadata' && order?.dynamicMetadataSchema) {
    metadataSchema = order.dynamicMetadataSchema;
  }

  // Return null for deleted metadata fields
  if (!metadataSchema?.properties[key]) {
    return null;
  }

  const description = metadataSchema.properties[key]?.description;
  if (description) {
    return description;
  }

  return t ? t(`activity.changes.${field}`) : `activity.changes.${field}`;
}

function ResolvedFieldValue({ field, value, order }: { field: string; value: unknown; order?: Order }) {
  const { t } = useIntl();
  // Always call hooks unconditionally to preserve hook order.
  const { data: organization } = useOrganization(field === 'organization' && typeof value === 'string' ? value : '');
  const { data: object } = useObject(field === 'object' && typeof value === 'string' ? value : '');
  const { data: globalMetadata } = useGlobalMetadata();

  if (value === null || value === undefined || value === '') {
    return <span className="font-medium">{t('activity.none')}</span>;
  }

  const needsMetadata = field.startsWith('metadata.') || field.startsWith('dynamicMetadata.');

  if (!needsMetadata && field !== 'organization' && field !== 'object') {
    if (field === 'startDate' || field === 'endDate') {
      const date = DateTime.fromISO(value as string);
      if (date.isValid) {
        return <span>{date.toFormat('dd.MM.yyyy')}</span>;
      }
    }
    return <span>{String(value)}</span>;
  }

  if (field === 'organization' && organization) {
    return <span>{organization.name}</span>;
  }

  if (field === 'object' && object) {
    return <span>{object.name}</span>;
  }

  if (field.startsWith('metadata.') || field.startsWith('dynamicMetadata.')) {
    const [type, key] = field.split('.');
    let metadataSchema: JsonSchemaType | undefined;

    if (type === 'metadata') {
      if (order?.type === 'root-order') {
        metadataSchema = globalMetadata?.rootOrderMetadata;
      } else if (order?.type === 'article-rental') {
        metadataSchema = globalMetadata?.articleRentalOrderMetadata;
      } else if (order?.type === 'deployment-planning') {
        metadataSchema = globalMetadata?.deploymentPlanningMetadata;
      }
    } else if (type === 'dynamicMetadata' && order?.dynamicMetadataSchema) {
      metadataSchema = order.dynamicMetadataSchema;
    }

    if (metadataSchema?.properties[key]) {
      const fieldDef = metadataSchema.properties[key];
      switch (fieldDef.type) {
        case 'date':
          return typeof value === 'string' && DateTime.fromISO(value).isValid ? (
            <span>{DateTime.fromISO(value).toFormat('dd.MM.yyyy')}</span>
          ) : (
            <span>{String(value)}</span>
          );
        case 'boolean':
          return <span>{t(value ? 'common.yes' : 'common.no')}</span>;
        case 'number':
          return <span>{typeof value === 'number' ? value.toString() : String(value)}</span>;
        default:
          return <span>{String(value)}</span>;
      }
    }
  }

  return <span>{String(value)}</span>;
}

interface OrderActivityProps {
  orderId?: string;
}

export function OrderActivity({ orderId }: OrderActivityProps) {
  const { t, locale } = useIntl();
  const {
    data: versionsData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useOrderVersions(orderId!, {
    disabled: !orderId,
    limit: 15,
  });

  const { data: order } = useOrder(orderId!);
  const { data: globalMetadata } = useGlobalMetadata();

  if (isLoading) {
    return <div className="text-center text-muted-foreground">{t('activity.loading')}</div>;
  }

  if (!versionsData?.pages[0].docs.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  // Flatten all pages of activities
  const allDocs = versionsData.pages.flatMap((page) => page.docs);
  const allActivities = transformVersionsToActivity(allDocs, getChanges);

  // Filter activities (existing filter logic)
  const activities = allActivities.filter((activity) => {
    if (activity.type === 'created') return !hasNextPage;
    return activity.changes?.some((change) => {
      const fieldName = getFieldName(change.field, order, globalMetadata, t);
      return fieldName !== null;
    });
  });

  if (!activities.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  return (
    <div className="space-y-4">
      <ActivityTimeline
        activities={activities}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
      >
        {(activity) =>
          activity.type === 'created' ? (
            <div className="flex items-center gap-1">
              <ResolvedUser userId={activity.user!} />
              <span className="text-muted-foreground">{t('activity.order.created')}</span>
              <span className="text-muted-foreground">
                · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
              </span>
            </div>
          ) : (
            activity.changes?.map((change, index) => {
              // Special handling for "no changes" case
              if (change.field === 'noChanges') {
                return (
                  <div key={index} className="flex items-center gap-1">
                    <ResolvedUser userId={activity.user!} />
                    <span className="text-muted-foreground">{t('activity.order.savedWithoutChanges')}</span>
                    <span className="text-muted-foreground">
                      · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                    </span>
                  </div>
                );
              }

              // Skip if field has been deleted
              const fieldName = getFieldName(change.field, order, globalMetadata, t);
              if (fieldName === null) return null;

              return (
                <div key={index} className="flex items-center gap-1">
                  <ResolvedUser userId={activity.user!} />
                  <span className="text-muted-foreground">{t('activity.changed')}</span>
                  {change.field.startsWith('metadata.') || change.field.startsWith('dynamicMetadata.') ? (
                    <span>
                      <span className="text-muted-foreground">{t('activity.metadataField')}</span>
                      <span className="font-medium"> {fieldName}</span>
                    </span>
                  ) : (
                    <span className="font-medium">{fieldName}</span>
                  )}
                  <span className="text-muted-foreground">{t('activity.from')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={change.field} value={change.from} order={order} />
                  </span>
                  <span className="text-muted-foreground">{t('activity.to')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={change.field} value={change.to} order={order} />
                  </span>
                  <span className="text-muted-foreground">
                    · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                  </span>
                </div>
              );
            })
          )
        }
      </ActivityTimeline>
    </div>
  );
}
