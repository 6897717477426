import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/components/ui/select';
import { useIntl } from '@tiny-intl/react';
import { CalendarRange, Table as TableIcon } from 'lucide-react';

interface ViewSelectProps {
  view: 'table' | 'timeline';
  onViewChange: (view: 'table' | 'timeline') => void;
  className?: string;
}

export function ViewSelect({ view, onViewChange, className }: ViewSelectProps) {
  const { t } = useIntl();

  return (
    <Select defaultValue="table" value={view} onValueChange={onViewChange}>
      <SelectTrigger className={className || 'w-[200px]'}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="table">
          <div className="flex items-center">
            <TableIcon className="size-4 mr-2 text-muted-foreground" />
            <span>{t('common.tableView')}</span>
          </div>
        </SelectItem>
        <SelectItem value="timeline">
          <div className="flex items-center">
            <CalendarRange className="size-4 mr-2 text-muted-foreground" />
            <span>{t('common.timelineView')}</span>
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
