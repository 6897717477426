import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { ChangeRequest } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const changeRequestKeys = {
  all: ['change-requests'] as const,
  lists: () => [...changeRequestKeys.all, 'list'] as const,
  list: (params: ChangeRequestQueryParams) => [...changeRequestKeys.lists(), params] as const,
  details: () => [...changeRequestKeys.all, 'detail'] as const,
  detail: (id: string) => [...changeRequestKeys.details(), id] as const,
} as const;

// Types for better type safety
export interface ChangeRequestQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof ChangeRequest | `-${keyof ChangeRequest}`;
  disabled?: boolean;
}

export interface ChangeRequestUpdateParams {
  id: string;
  data: Partial<ChangeRequest>;
}

// Hooks with improved TypeScript types
export const useChangeRequests = (params?: ChangeRequestQueryParams) => {
  return useQuery({
    queryKey: changeRequestKeys.list(params ?? {}),
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'change-requests',
        ...params,
      });
      return response;
    },
  });
};

export const useChangeRequest = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: changeRequestKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'change-requests',
        id,
      });
      return response;
    },
  });
};

export const useCreateChangeRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<ChangeRequest>) => {
      const response = await payloadClient.create({
        collection: 'change-requests',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: changeRequestKeys.all });
    },
  });
};

// Custom endpoint for approving/rejecting change requests
export const useReviewChangeRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, action }: { id: string; action: 'accept' | 'reject' }) => {
      const response = await payloadClient.customRequest<ChangeRequest>({
        subpath: `/change-requests/${id}/${action}`,
        method: 'POST',
      });
      return response;
    },
    onSuccess: (_, { id }) => {
      // Invalidate all change requests queries
      queryClient.invalidateQueries({ queryKey: changeRequestKeys.all });
      // Invalidate the specific change request query
      queryClient.invalidateQueries({ queryKey: changeRequestKeys.detail(id) });
    },
  });
};
