import { useResolvedArticleChildren, useResolvedTemplateChildren, useTemplate } from '@/core/api';
import type { ArticleChildrenData } from '@/core/api';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/shared/components/ui/accordion';
import AutoFormObject from '@/shared/components/ui/auto-form/fields/object';
import type { FieldConfigItem } from '@/shared/components/ui/auto-form/types';
import { Badge } from '@/shared/components/ui/badge';
import { Button } from '@/shared/components/ui/button';
import { Card } from '@/shared/components/ui/card';
import { DatePicker } from '@/shared/components/ui/date-picker';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/components/ui/drawer';
import { FormField, FormItem, FormLabel } from '@/shared/components/ui/form';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/components/ui/tooltip';
import { cn } from '@/shared/utils/cn';
import type { JsonSchemaType, ResolvedChildrenMap, Template } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { Edit2Icon, RefreshCwIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type FieldValues, type UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

interface ArticleChildrenProps {
  template?: Template;
  form: UseFormReturn<FieldValues>;
  onSchemaChange?: (schema: z.ZodType) => void;
  articleId?: string;
  sourceArticleId?: string;
}

interface ChildArticleFormProps {
  templateId: string;
  config: ResolvedChildrenMap[string];
  basePath: string[];
  form: UseFormReturn<FieldValues>;
  multiEditForm: UseFormReturn<FieldValues>;
  existingChildren?: ArticleChildrenData;
}

interface MultiEditState {
  [field: string]: boolean;
}

const createMetadataSchema = (metadataSchema: JsonSchemaType) => {
  const zodSchemaString = jsonSchemaToZod(metadataSchema);
  const transformedSchema = zodSchemaString
    .replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()')
    // Add coercion for number fields
    .replace(/z\.number\(\)/g, 'z.coerce.number()');

  return z.object({
    expiryDate: z.date().optional().nullable(),
    metadata: eval(transformedSchema),
  });
};

const createArticleChildSchema = (templateStructure: ResolvedChildrenMap): z.ZodType => {
  const schema: Record<string, z.ZodType> = {};

  Object.entries(templateStructure).forEach(([templateId, config]) => {
    let itemSchema = createMetadataSchema(config.metadataSchema);

    if (config.children) {
      itemSchema = itemSchema.extend({
        children: z.object(
          Object.fromEntries(
            Object.entries(config.children).map(([childId, childConfig]) => [
              childId,
              z.array(createMetadataSchema(childConfig.metadataSchema)).length(childConfig.quantity),
            ]),
          ),
        ),
      });
    }

    schema[templateId] = z.array(itemSchema).length(config.quantity);
  });

  return z.object(schema);
};

const isDataComplete = (
  formData: FieldValues | undefined,
  schema: JsonSchemaType,
  quantity: number,
  children?: ResolvedChildrenMap,
): boolean => {
  if (!formData) return false;

  // Check if array length matches quantity
  if (Array.isArray(formData) && formData.length !== quantity) {
    return false;
  }

  // For each item in the array
  if (Array.isArray(formData)) {
    return formData.every((item) => {
      // Skip undefined or null items
      if (!item) return false;

      // Check required metadata fields
      const requiredFields = schema.required || [];
      const hasAllRequiredFields = requiredFields.every(
        (field) => item.metadata && item.metadata[field] !== undefined && item.metadata[field] !== '',
      );

      // Check if children are required but missing
      if (children) {
        if (!item.children) return false;

        // Check if all required child templates are present and complete
        const hasAllRequiredChildren = Object.entries(children).every(([childId, childConfig]) => {
          const childArray = item.children[childId];
          // First check if the array exists and has correct length
          if (!Array.isArray(childArray) || childArray.length !== childConfig.quantity) {
            return false;
          }
          // Then check each child item in the array
          return childArray.every((childItem) => {
            if (!childItem) return false;
            const childFormData = [childItem]; // Wrap in array since isDataComplete expects array
            return isDataComplete(childFormData, childConfig.metadataSchema, 1, childConfig.children);
          });
        });

        return hasAllRequiredFields && hasAllRequiredChildren;
      }

      return hasAllRequiredFields;
    });
  }

  return true;
};

const DataCompleteBadge = ({ isComplete }: { isComplete: boolean }) => {
  const { t } = useIntl();
  return (
    <Badge
      variant={isComplete ? 'outline' : 'destructive'}
      className={cn('ml-2', isComplete ? 'bg-green-600/90 text-white' : '')}
    >
      {isComplete ? t('articles.multiEdit.dataComplete') : t('articles.multiEdit.dataIncomplete')}
    </Badge>
  );
};

function parseDate(value: string | null | undefined): Date | null {
  return value ? new Date(value) : null;
}

function parseMetadata(metadata?: Record<string, unknown>): Record<string, unknown> {
  if (!metadata) return {};

  const entries = Object.entries(metadata).sort((a, b) => a[0].localeCompare(b[0]));

  return Object.fromEntries(
    entries.map(([key, value]) => {
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        return [key, DateTime.fromISO(value).toJSDate()];
      }
      return [key, value];
    }),
  );
}

function parseChildrenData(data: ArticleChildrenData, isCopy: boolean = false): FieldValues {
  const result: Record<string, unknown[]> = {};

  for (const [templateId, articles] of Object.entries(data)) {
    result[templateId] = articles.map((article) => {
      // When copying, we only want the data without IDs
      const parsedArticle = {
        metadata: parseMetadata(article.metadata),
        expiryDate: parseDate(article.expiryDate),
        children: article.children ? parseChildrenData(article.children, isCopy) : undefined,
      };

      // Only include IDs if not copying
      if (!isCopy) {
        return {
          ...parsedArticle,
          id: article.id,
        };
      }

      return parsedArticle;
    });
  }

  return result;
}

function areAllValuesEqual(values: unknown[]): boolean {
  if (values.length <= 1) return false;
  const firstValue = values[0];

  if (firstValue instanceof Date) {
    return values.every((value) => value instanceof Date && value.getTime() === firstValue.getTime());
  }

  return values.every((value) => value === firstValue);
}

function ChildArticleForm({
  templateId,
  config,
  basePath,
  form,
  multiEditForm,
  existingChildren,
}: ChildArticleFormProps) {
  const { t } = useIntl();
  const { data: templateData } = useTemplate(templateId);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [individuallyEditedFields, setIndividuallyEditedFields] = useState<MultiEditState>({});
  const [forcedMultiEditFields, setForcedMultiEditFields] = useState<Set<string>>(new Set());
  const [isInitialized, setIsInitialized] = useState(false);

  const formData = form.watch(basePath.slice(0, -1).join('.'));
  // For container templates, we need to evaluate completeness for each instance separately
  const getInstanceCompleteness = (index: number) => {
    if (!Array.isArray(formData)) return false;
    const instanceData = formData[index];
    if (!instanceData) return false;
    return isDataComplete([instanceData], config.metadataSchema, 1, config.children);
  };
  // For non-container templates, we can evaluate the entire array
  const isComplete = config.children
    ? getInstanceCompleteness(parseInt(basePath[basePath.length - 2]) || 0)
    : isDataComplete(formData, config.metadataSchema, config.quantity, config.children);

  const zodSchema = useMemo(() => {
    const zodSchemaString = jsonSchemaToZod(config.metadataSchema);
    const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
    return eval(transformedSchema);
  }, [config.metadataSchema]);

  // Create watch paths for all fields
  const watchPaths = useMemo(() => {
    const fields = [
      'expiryDate',
      ...Object.keys(config.metadataSchema.properties || {}).map((field) => `metadata.${field}`),
    ];

    return fields.flatMap((field) =>
      Array.from({ length: config.quantity }).map((_, i) =>
        [...basePath.slice(0, -1), i.toString(), ...field.split('.')].join('.'),
      ),
    );
  }, [config.metadataSchema, config.quantity, basePath]);

  // Watch all field values
  const fieldValues = useWatch({
    control: form.control,
    name: watchPaths,
  });

  // Update multi-edit state when field values change
  useEffect(() => {
    if (!Array.isArray(formData)) return;

    const fields = [
      'expiryDate',
      ...Object.keys(config.metadataSchema.properties || {}).map((field) => `metadata.${field}`),
    ];

    fields.forEach((field, fieldIndex) => {
      // Get all values for this field
      const startIndex = fieldIndex * config.quantity;
      const values = fieldValues.slice(startIndex, startIndex + config.quantity);

      const hasCommonValue = areAllValuesEqual(values);

      // Update multi-edit form if values are equal or if field is in forced multi-edit mode
      if (hasCommonValue || forcedMultiEditFields.has(field)) {
        multiEditForm.setValue(field, values[0], { shouldDirty: false });
      }

      // Update individually edited state
      setIndividuallyEditedFields((prev) => {
        // Only update if:
        // 1. Not manually set to individual edit, OR
        // 2. Values are now equal, OR
        // 3. Field is in forced multi-edit mode
        if (!prev[field] || hasCommonValue || forcedMultiEditFields.has(field)) {
          return { ...prev, [field]: !hasCommonValue && !forcedMultiEditFields.has(field) };
        }
        return prev;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues, config.metadataSchema, config.quantity, multiEditForm, forcedMultiEditFields]);

  // Pre-fill form with existing data if available
  useEffect(() => {
    if (!isInitialized && existingChildren && existingChildren[templateId]) {
      const childrenData = parseChildrenData({ [templateId]: existingChildren[templateId] }, false)[templateId];
      form.setValue(basePath.slice(0, -1).join('.'), childrenData, { shouldDirty: false });
      setIsInitialized(true);
    }
  }, [existingChildren, templateId, form, basePath, isInitialized]);

  const handleMultiEdit = (field: string, value: unknown) => {
    // Apply the value to all items
    Array.from({ length: config.quantity }).forEach((_, i) => {
      const path = [...basePath.slice(0, -1), i.toString(), ...field.split('.')];
      form.setValue(path.join('.'), value);
    });
  };

  const handleIndividualEdit = (field: string) => {
    setIndividuallyEditedFields((prev) => ({ ...prev, [field]: true }));
    setForcedMultiEditFields((prev) => {
      const next = new Set(prev);
      next.delete(field);
      return next;
    });
  };

  const resetMultiEdit = () => {
    if (Array.isArray(formData)) {
      const fields = [
        'expiryDate',
        ...Object.keys(config.metadataSchema.properties || {}).map((field) => `metadata.${field}`),
      ];

      // Reset individually edited state and enable forced multi-edit for all fields
      setIndividuallyEditedFields({});
      setForcedMultiEditFields(new Set(fields));

      // Update multi-edit form with values from the first item
      fields.forEach((field) => {
        let value;
        if (field === 'expiryDate') {
          value = (formData[0] as { expiryDate?: Date | null })?.expiryDate;
        } else {
          const metadataField = field.replace('metadata.', '');
          value = (formData[0] as { metadata?: Record<string, unknown> })?.metadata?.[metadataField];
        }
        multiEditForm.setValue(field, value, { shouldDirty: false });
      });
    }
  };

  const IndividualEditOverlay = ({ className }: { className?: string }) => (
    <div
      className={cn(
        'absolute inset-0 flex items-center justify-center bg-background/10 backdrop-blur-[1px] text-xs text-muted-foreground cursor-not-allowed',
        className,
      )}
    >
      {t('articles.multiEdit.individualEditActive')}
    </div>
  );

  const renderMultiEditCard = (inDrawer: boolean = false) => {
    if (config.children || config.quantity <= 1) return null;

    const fieldConfig = Object.keys(config.metadataSchema.properties || {}).reduce(
      (acc, field) => {
        acc[field] = {
          inputProps: {
            onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
              handleMultiEdit(`metadata.${field}`, e.target.value);
            },
            disabled: individuallyEditedFields[`metadata.${field}`],
            className: cn(individuallyEditedFields[`metadata.${field}`] && 'opacity-50'),
          },
          renderParent: ({ children }) => (
            <div className="relative">
              {children}
              {individuallyEditedFields[`metadata.${field}`] && <IndividualEditOverlay className="mt-[20px]" />}
            </div>
          ),
        };
        return acc;
      },
      {} as Record<string, FieldConfigItem>,
    );

    const content = (
      <div className="space-y-5">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <Badge variant="outline" className="bg-primary/5 text-primary">
              {t('articles.multiEdit.badge')}
            </Badge>
            <span>{t('articles.multiEdit.explanation')}</span>
          </div>
          {inDrawer && (
            <Button variant="outline" size="sm" onClick={resetMultiEdit} className="shrink-0">
              <RefreshCwIcon className="!size-3" />
              {t('articles.multiEdit.reset')}
            </Button>
          )}
        </div>
        <div className="grid grid-cols-3 gap-5">
          <FormField
            control={multiEditForm.control}
            name="expiryDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('articles.expiryDate')}</FormLabel>
                <div className="relative">
                  <div className={cn('rounded-md', individuallyEditedFields['expiryDate'] && 'opacity-50')}>
                    <DatePicker
                      date={field.value}
                      setDate={(date) => {
                        field.onChange(date);
                        handleMultiEdit('expiryDate', date);
                      }}
                      disabled={individuallyEditedFields['expiryDate']}
                      placeholder={t('articles.chooseExpiryDate')}
                    />
                  </div>
                  {individuallyEditedFields['expiryDate'] && <IndividualEditOverlay />}
                </div>
              </FormItem>
            )}
          />
        </div>
        <AutoFormObject
          className="grid grid-cols-3 gap-5 !space-y-0"
          schema={zodSchema}
          form={multiEditForm}
          path={['metadata']}
          fieldConfig={fieldConfig}
        />
      </div>
    );

    if (!inDrawer) {
      return (
        <div>
          <div className="rounded-t-lg border p-4">
            {content}
            <div className="text-xs text-muted-foreground mt-4">{t('articles.multiEdit.helperText')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="mb-6">
        <div className="rounded-lg border p-4">{content}</div>
      </div>
    );
  };

  const renderArticleForm = (itemIndex: number, insideDrawer: boolean = false) => {
    const itemBasePath = [...basePath.slice(0, -1), itemIndex.toString()];

    const fieldConfig = Object.keys(config.metadataSchema.properties || {}).reduce(
      (acc, field) => {
        acc[field] = {
          inputProps: {
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              // Mark field as individually edited
              handleIndividualEdit(`metadata.${field}`);
              // Let the form handle the value change
              form.setValue([...itemBasePath, 'metadata', field].join('.'), e.target.value);
            },
          },
        };
        return acc;
      },
      {} as Record<string, FieldConfigItem>,
    );

    return (
      <Card key={itemIndex} className={cn('border-solid border border-border p-4', insideDrawer && 'mb-4')}>
        <div className="space-y-5">
          {insideDrawer && (
            <div className="flex items-center gap-2">
              <span className="font-medium text-sm">
                {templateData?.name} #{itemIndex + 1}
              </span>
            </div>
          )}
          <div className={cn('grid grid-cols-5 gap-5', insideDrawer && 'grid-cols-3')}>
            <FormField
              control={form.control}
              name={[...itemBasePath, 'expiryDate'].join('.')}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('articles.expiryDate')}</FormLabel>
                  {config.children ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <DatePicker
                              date={field.value}
                              setDate={field.onChange}
                              disabled={true}
                              placeholder={t('articles.chooseExpiryDate')}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>{t('templates.container.containerExpiryDateDescription')}</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : (
                    <DatePicker
                      date={field.value}
                      setDate={(date) => {
                        field.onChange(date);
                        handleIndividualEdit('expiryDate');
                      }}
                      disabled={field.disabled}
                      placeholder={t('articles.chooseExpiryDate')}
                    />
                  )}
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <AutoFormObject
              className={cn('grid grid-cols-5 gap-5 !space-y-0', insideDrawer && 'grid-cols-3')}
              schema={zodSchema}
              form={form}
              path={[...itemBasePath, 'metadata']}
              fieldConfig={fieldConfig}
            />
          </div>
          {config.children && (
            <div className="space-y-4">
              <h3 className="font-medium">{t('contents.childArticles')}</h3>
              {Object.entries(config.children).map(([childId, childConfig]) => (
                <MultiEditFormProvider key={childId}>
                  {(childMultiEditForm) => (
                    <ChildArticleForm
                      key={childId}
                      templateId={childId}
                      config={childConfig}
                      basePath={[...itemBasePath, 'children', childId, '0']}
                      form={form}
                      multiEditForm={childMultiEditForm}
                      existingChildren={existingChildren}
                    />
                  )}
                </MultiEditFormProvider>
              ))}
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="space-y-4">
      {config.children ? (
        // For container templates, render individual cards
        Array.from({ length: config.quantity }).map((_, i) => (
          <Card key={i}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1" className="border-none">
                <AccordionTrigger className="px-6 py-4">
                  <div className="flex items-start justify-between gap-2 w-full pr-3">
                    <div className="flex items-center gap-2">
                      <span className="font-medium text-sm">
                        {templateData?.name} #{i + 1}
                      </span>
                      <Badge variant="secondary">{t('templates.container.containerTemplate')}</Badge>
                    </div>

                    <DataCompleteBadge isComplete={getInstanceCompleteness(i)} />
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4">{renderArticleForm(i)}</AccordionContent>
              </AccordionItem>
            </Accordion>
          </Card>
        ))
      ) : (
        // For non-container templates, keep the existing drawer behavior
        <Card>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1" className="border-none">
              <AccordionTrigger className="px-6 py-4">
                <div className="flex items-start justify-between gap-2 w-full pr-3">
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-sm">
                      {config.quantity}x {templateData?.name}
                    </span>
                  </div>
                  <DataCompleteBadge isComplete={isComplete} />
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-6 pb-4">
                {config.quantity === 1 ? (
                  renderArticleForm(0)
                ) : (
                  <>
                    {renderMultiEditCard(false)}
                    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
                      <DrawerTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="w-full flex items-center justify-center gap-2  hover:bg-muted/20 rounded-t-none border-t-0"
                        >
                          <Edit2Icon className="!size-3.5" />
                          {t('articles.multiEdit.openIndividualEditing')}
                        </Button>
                      </DrawerTrigger>
                      <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none max-w-[1000px]">
                        <DrawerDescription className="sr-only">
                          {t('articles.multiEdit.editingItems', {
                            name: templateData?.name ?? templateId,
                            count: config.quantity,
                          })}
                        </DrawerDescription>
                        <DrawerHeader>
                          <DrawerTitle>
                            {t('articles.multiEdit.editingItems', {
                              name: templateData?.name ?? templateId,
                              count: config.quantity,
                            })}
                          </DrawerTitle>
                        </DrawerHeader>
                        <div className="p-4 overflow-y-auto">
                          {renderMultiEditCard(true)}
                          {Array.from({ length: config.quantity }).map((_, i) => renderArticleForm(i, true))}
                        </div>
                      </DrawerContent>
                    </Drawer>
                  </>
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </Card>
      )}
    </div>
  );
}

// Component to manage a single multi-edit form
function MultiEditFormProvider({ children }: { children: (form: UseFormReturn<FieldValues>) => React.ReactNode }) {
  const multiEditForm = useForm<FieldValues>();

  return <>{children(multiEditForm)}</>;
}

export function ArticleChildren({ template, form, onSchemaChange, articleId, sourceArticleId }: ArticleChildrenProps) {
  const { data: templateChildrenData } = useResolvedTemplateChildren(template?.id);
  const { data: existingChildrenData } = useResolvedArticleChildren(articleId || '', { disabled: !articleId });
  const { data: sourceArticleChildrenData } = useResolvedArticleChildren(sourceArticleId || '', {
    disabled: !sourceArticleId,
  });
  const lastSchemaRef = useRef<z.ZodType | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const schema = useMemo(() => {
    if (!templateChildrenData?.children) return null;
    return createArticleChildSchema(templateChildrenData.children);
  }, [templateChildrenData]);

  useEffect(() => {
    if (schema && onSchemaChange && schema !== lastSchemaRef.current) {
      lastSchemaRef.current = schema;
      onSchemaChange(schema);
    }
  }, [schema, onSchemaChange]);

  // Pre-fill form with existing data if available
  useEffect(() => {
    if (!isInitialized && form) {
      if (sourceArticleChildrenData?.children) {
        // If we're copying, use the source article's data without IDs
        const parsedChildren = parseChildrenData(sourceArticleChildrenData.children, true);
        form.reset(parsedChildren, { keepDefaultValues: true });
        setIsInitialized(true);
      } else if (existingChildrenData?.children) {
        // If we're editing, use the existing data with IDs
        const parsedChildren = parseChildrenData(existingChildrenData.children, false);
        form.reset(parsedChildren, { keepDefaultValues: true });
        setIsInitialized(true);
      }
    }
  }, [existingChildrenData, sourceArticleChildrenData, form, isInitialized]);

  if (!templateChildrenData?.children || !schema) return null;

  return (
    <div className="space-y-4">
      {Object.entries(templateChildrenData.children).map(([templateId, config], index) => (
        <MultiEditFormProvider key={templateId}>
          {(multiEditForm) => (
            <ChildArticleForm
              templateId={templateId}
              config={config}
              basePath={[templateId, index.toString()]}
              form={form}
              multiEditForm={multiEditForm}
              existingChildren={sourceArticleId ? sourceArticleChildrenData?.children : existingChildrenData?.children}
            />
          )}
        </MultiEditFormProvider>
      ))}
    </div>
  );
}
