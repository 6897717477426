/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMetadataGroups } from '@/core/api/metadata-groups';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import type { useForm } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import * as z from 'zod';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../accordion';
import { FormField } from '../../form';
import { Label } from '../../label';
import { DEFAULT_ZOD_HANDLERS, INPUT_COMPONENTS } from '../config';
import resolveDependencies from '../dependencies';
import type { Dependency, FieldConfig, FieldConfigItem } from '../types';
import { beautifyObjectName, getBaseSchema, getBaseType, zodToHtmlInputProps } from '../utils';
import AutoFormArray from './array';

function DefaultParent({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

type GroupedFields = {
  [group: string]: string[];
  ungrouped: string[];
};

export default function AutoFormObject<SchemaType extends z.ZodObject<any, any>>({
  schema,
  form,
  fieldConfig,
  path = [],
  dependencies = [],
  className,
  metadataSchema,
}: {
  schema: SchemaType | z.ZodEffects<SchemaType>;
  form: ReturnType<typeof useForm>;
  fieldConfig?: FieldConfig<z.infer<SchemaType>>;
  path?: string[];
  dependencies?: Dependency<z.infer<SchemaType>>[];
  className?: string;
  metadataSchema?: JsonSchemaType;
}) {
  const { watch } = useFormContext();
  const { data: metadataGroupsResponse } = useMetadataGroups();
  const metadataGroups = metadataGroupsResponse?.docs ?? [];

  if (!schema) {
    return null;
  }
  const { shape } = getBaseSchema<SchemaType>(schema) || {};

  if (!shape) {
    return null;
  }

  // Group fields based on metadata schema
  const groupedFields: GroupedFields = {
    ungrouped: [],
  };

  if (metadataSchema?.properties) {
    Object.entries(shape).forEach(([name]) => {
      const fieldMetadata = metadataSchema.properties[name];
      if (fieldMetadata && typeof fieldMetadata === 'object' && 'group' in fieldMetadata) {
        const groupId = fieldMetadata.group as string | null;
        if (groupId) {
          const group = metadataGroups.find((g) => g.id === groupId);
          if (group) {
            if (!groupedFields[group.name]) {
              groupedFields[group.name] = [];
            }
            groupedFields[group.name].push(name);
          } else {
            groupedFields.ungrouped.push(name);
          }
        } else {
          groupedFields.ungrouped.push(name);
        }
      } else {
        groupedFields.ungrouped.push(name);
      }
    });
  } else {
    groupedFields.ungrouped = Object.keys(shape);
  }

  const handleIfZodNumber = (item: z.ZodAny) => {
    const isZodNumber = (item as any)._def.typeName === 'ZodNumber';
    const isInnerZodNumber = (item._def as any).innerType?._def?.typeName === 'ZodNumber';

    if (isZodNumber) {
      (item as any)._def.coerce = true;
    } else if (isInnerZodNumber) {
      (item._def as any).innerType._def.coerce = true;
    }

    return item;
  };

  const getCustomerAccess = (name: string, metadataSchema?: JsonSchemaType) => {
    if (!metadataSchema?.properties) return 'read';
    return (metadataSchema.properties[name]?.customerAccess as 'none' | 'read' | 'read-and-write') || 'read';
  };

  const renderField = (name: string) => {
    let item = shape[name] as z.ZodAny;
    item = handleIfZodNumber(item) as z.ZodAny;
    const zodBaseType = getBaseType(item);
    const itemName = item._def.description ?? beautifyObjectName(name);
    const key = [...path, name].join('.');

    const {
      isHidden,
      isDisabled: baseIsDisabled,
      isRequired: isRequiredByDependency,
      overrideOptions,
    } = resolveDependencies(dependencies, name, watch);

    const globalFieldConfig = fieldConfig?.['*'] as FieldConfigItem & {
      metadataSchema?: JsonSchemaType;
      isCustomer?: boolean;
    };
    const isCustomer = globalFieldConfig?.isCustomer;
    const metadataSchema = globalFieldConfig?.metadataSchema;

    // Handle customer access permissions
    let isDisabled = baseIsDisabled;
    if (isCustomer) {
      const customerAccess = getCustomerAccess(name, metadataSchema);
      if (customerAccess === 'none') {
        return null;
      }
      if (customerAccess === 'read') {
        isDisabled = true;
      }
    }

    if (isHidden) {
      return null;
    }

    if (zodBaseType === 'ZodObject') {
      return (
        <AccordionItem value={name} key={key} className="border-none">
          <AccordionTrigger>{itemName}</AccordionTrigger>
          <AccordionContent className="p-2">
            <AutoFormObject
              schema={item as unknown as z.ZodObject<any, any>}
              form={form}
              fieldConfig={(fieldConfig?.[name] ?? {}) as FieldConfig<z.infer<typeof item>>}
              path={[...path, name]}
              metadataSchema={metadataSchema}
            />
          </AccordionContent>
        </AccordionItem>
      );
    }
    if (zodBaseType === 'ZodArray') {
      return (
        <AutoFormArray
          key={key}
          name={name}
          item={item as unknown as z.ZodArray<any>}
          form={form}
          fieldConfig={fieldConfig?.[name] ?? {}}
          path={[...path, name]}
        />
      );
    }

    const fieldConfigItem: FieldConfigItem = {
      ...(fieldConfig?.['*'] ?? {}),
      ...(fieldConfig?.[name] ?? {}),
    };
    const zodInputProps = zodToHtmlInputProps(item);
    const isRequired =
      isRequiredByDependency || zodInputProps.required || fieldConfigItem.inputProps?.required || false;

    if (overrideOptions) {
      item = z.enum(overrideOptions) as unknown as z.ZodAny;
    }

    return (
      <FormField
        control={form.control}
        name={key}
        key={key}
        render={({ field }) => {
          const inputType = fieldConfigItem.fieldType ?? DEFAULT_ZOD_HANDLERS[zodBaseType] ?? 'fallback';

          const InputComponent = typeof inputType === 'function' ? inputType : INPUT_COMPONENTS[inputType];

          const ParentElement = fieldConfigItem.renderParent ?? DefaultParent;

          const defaultValue = fieldConfigItem.inputProps?.defaultValue;
          const value = field.value ?? defaultValue ?? '';

          const fieldProps = {
            ...zodToHtmlInputProps(item),
            ...field,
            ...fieldConfigItem.inputProps,
            disabled: fieldConfigItem.inputProps?.disabled || isDisabled,
            ref: undefined,
            value: value,
          };

          if (InputComponent === undefined) {
            return <></>;
          }

          return (
            <ParentElement key={`${key}.parent`}>
              <InputComponent
                zodInputProps={zodInputProps}
                field={field}
                fieldConfigItem={fieldConfigItem}
                label={itemName}
                isRequired={isRequired}
                zodItem={item}
                fieldProps={fieldProps}
                className={fieldProps.className}
              />
            </ParentElement>
          );
        }}
      />
    );
  };

  return (
    <Accordion type="multiple" className={`space-y-5 border-none ${className || ''}`}>
      {Object.entries(groupedFields).map(([group, fields]) => {
        if (fields.length === 0) return null;

        return (
          <div key={group} className="space-y-4">
            {group !== 'ungrouped' && (
              <>
                <Label>{group}</Label>
                <div className="relative">
                  <div className="absolute left-2 inset-y-0 w-px bg-muted" />
                  <div className="space-y-4 pl-8">{fields.map(renderField)}</div>
                </div>
              </>
            )}
            {group === 'ungrouped' && <div className="space-y-4">{fields.map(renderField)}</div>}
          </div>
        );
      })}
    </Accordion>
  );
}
