import type { MetadataGroup } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useMetadataGroups = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof MetadataGroup | `-${keyof MetadataGroup}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['metadata-groups', params],
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'metadata-groups',
        ...params,
      });
      return response;
    },
  });
};

export const useMetadataGroup = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['metadata-group', id, params],
    enabled: !params?.disabled && !!id,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'metadata-groups',
        id,
      });
      return response;
    },
  });
};

export const useCreateMetadataGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Partial<MetadataGroup>) => {
      const response = await payloadClient.create({
        collection: 'metadata-groups',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['metadata-groups'] });
    },
  });
};

export const useUpdateMetadataGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<MetadataGroup> }) => {
      const response = await payloadClient.updateById({
        collection: 'metadata-groups',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['metadata-groups'] });
    },
  });
};

export const useDeleteMetadataGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'metadata-groups',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['metadata-groups'] });
    },
  });
};
