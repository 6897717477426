/* eslint-disable no-console */
import type { ColumnFiltersState, PaginationState, SortingState, VisibilityState } from '@tanstack/react-table';

export interface TablePreferences {
  pagination?: PaginationState;
  sorting?: SortingState;
  columnFilters?: ColumnFiltersState;
  columnVisibility?: VisibilityState;
  view?: string;
  searchTerm?: string;
  selectedFilters?: Record<string, unknown>;
}

export interface TablePreferencesOptions {
  /** The unique identifier for the table */
  tableId: string;
  /** Default preferences to use if none are stored */
  defaultPreferences?: TablePreferences;
}

const TABLE_PREFERENCES_PREFIX = 'table_preferences_';

export class TablePreferencesService {
  private tableId: string;
  private storageKey: string;
  private defaultPreferences: TablePreferences;

  constructor({ tableId, defaultPreferences = {} }: TablePreferencesOptions) {
    this.tableId = tableId;
    this.storageKey = `${TABLE_PREFERENCES_PREFIX}${tableId}`;
    this.defaultPreferences = defaultPreferences;
  }

  /**
   * Load preferences from localStorage
   */
  loadPreferences(): TablePreferences {
    try {
      const stored = localStorage.getItem(this.storageKey);
      if (!stored) return this.defaultPreferences;

      const preferences = JSON.parse(stored) as TablePreferences;
      return { ...this.defaultPreferences, ...preferences };
    } catch (error) {
      console.error(`Error loading table preferences for ${this.tableId}:`, error);
      return this.defaultPreferences;
    }
  }

  /**
   * Save preferences to localStorage
   */
  savePreferences(preferences: TablePreferences): void {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(preferences));
    } catch (error) {
      console.error(`Error saving table preferences for ${this.tableId}:`, error);
    }
  }

  /**
   * Update specific preference fields
   */
  updatePreferences(update: Partial<TablePreferences>): void {
    const current = this.loadPreferences();
    this.savePreferences({ ...current, ...update });
  }

  /**
   * Clear stored preferences
   */
  clearPreferences(): void {
    localStorage.removeItem(this.storageKey);
  }
}

/**
 * React hook to manage table preferences
 */
export function useTablePreferences(options: TablePreferencesOptions) {
  const service = new TablePreferencesService(options);

  return {
    loadPreferences: () => service.loadPreferences(),
    savePreferences: (preferences: TablePreferences) => service.savePreferences(preferences),
    updatePreferences: (update: Partial<TablePreferences>) => service.updatePreferences(update),
    clearPreferences: () => service.clearPreferences(),
  };
}
