import { useCategories, useDeleteTemplate, useTemplates } from '@/core/api';
import { useAuth } from '@/core/auth';
import { SearchField } from '@/shared/components/inputs';
import { PageHeading } from '@/shared/components/layout';
import { DataTablePagination, TableLoadingCell } from '@/shared/components/tables';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  EmptyTableRow,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useDebounce } from '@/shared/hooks/use-debounce';
import { useTablePreferences } from '@/shared/services/table-preferences';
import type { Template } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { Filter, MoreHorizontal, PlusCircle } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const CategoryCell = ({ row }: { row: Row<Template> }) => {
  const { data: categories } = useCategories({ limit: 0 });
  const categoryId = row.getValue('category') as string;
  const category = categories?.docs.find((cat) => cat.id === categoryId);
  return <div>{category?.name || '-'}</div>;
};

const ActionCell = ({ template, onDelete }: { template: Template; onDelete: (template: Template) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhAdmin = user?.role === 'juh-admin';
  const isDeleteDisabled = (template.articleCount ?? 0) > 0;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`/juh/article-management/templates/${template.id}`)}>
          {t('common.edit')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/juh/article-management/templates/new?copy=${template.id}`)}>
          {t('templates.copyTemplate')}
        </DropdownMenuItem>
        <DropdownMenuItem disabled={!isJuhAdmin || isDeleteDisabled} onClick={() => onDelete(template)}>
          {t('common.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const TemplateOverview = () => {
  const { t } = useIntl();
  const navigate = useNavigate();

  const tablePreferences = useTablePreferences({
    tableId: 'templates-overview',
    defaultPreferences: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting: [],
      columnFilters: [],
      columnVisibility: {},
      searchTerm: '',
      selectedFilters: {
        activeTab: 'all',
      },
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();

  const [searchTerm, setSearchTerm] = useState(preferences.searchTerm || '');
  const [activeTab, setActiveTab] = useState<'all' | 'container' | 'single'>(
    (preferences.selectedFilters?.activeTab as 'all' | 'container' | 'single') || 'all',
  );
  const [sorting, setSorting] = useState<SortingState>(preferences.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(preferences.columnFilters || []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(preferences.columnVisibility || {});
  const [pagination, setPagination] = useState(
    preferences.pagination || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      searchTerm,
      selectedFilters: {
        activeTab,
      },
    });
  }, [tablePreferences, pagination, sorting, columnFilters, columnVisibility, searchTerm, activeTab]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev: { pageIndex: number; pageSize: number }) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const columns: ColumnDef<Template>[] = [
    {
      accessorKey: 'name',
      header: t('common.name'),
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'category',
      header: t('categories.category'),
      cell: ({ row }) => <CategoryCell row={row} />,
    },
    {
      accessorKey: 'isContainer',
      header: t('templates.container.isContainer'),
      cell: ({ row }) => {
        const isContainer = row.getValue('isContainer') as boolean;
        return <div>{isContainer ? t('common.yes') : t('common.no')}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell template={row.original} onDelete={setTemplateToDelete} />,
    },
  ];

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [rowSelection, setRowSelection] = useState({});

  const { data: templatesData, isLoading } = useTemplates({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: {
      ...(activeTab === 'all' ? {} : { isContainer: { equals: activeTab === 'container' } }),
      ...(debouncedSearchTerm
        ? { or: [{ name: { like: debouncedSearchTerm } }, { 'category.name': { like: debouncedSearchTerm } }] }
        : {}),
    },
  });

  const templates = templatesData?.docs || [];
  const totalPages = templatesData?.totalPages || 0;

  const table = useReactTable({
    data: templates,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
      setPagination(newValue);
    },
    manualPagination: true,
    onSortingChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
      setSorting(newValue);
    },
    onColumnFiltersChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(columnFilters) : updaterOrValue;
      setColumnFilters(newValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(columnVisibility) : updaterOrValue;
      setColumnVisibility(newValue);
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteTemplate = useDeleteTemplate();
  const [templateToDelete, setTemplateToDelete] = useState<Template | null>(null);

  const confirmDeleteTemplate = async () => {
    if (templateToDelete) {
      try {
        await deleteTemplate.mutateAsync(templateToDelete.id);
        toast.success(t('templates.templateDeletedSuccessfully'));
        setTemplateToDelete(null);
      } catch (error) {
        toast.error(t('templates.errorDeletingTemplate'));
      }
    }
  };

  return (
    <>
      <PageHeading>{t('templates.templates')}</PageHeading>

      <div className="flex items-center">
        <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
        <div className="ml-auto flex items-center gap-4">
          <Select
            value={activeTab}
            onValueChange={(value) => {
              setActiveTab(value as 'all' | 'container' | 'single');
              setPagination((prev) => ({ ...prev, pageIndex: 0 }));
            }}
          >
            <SelectTrigger className="min-w-[200px] w-auto">
              <div className="flex items-center">
                <Filter className="size-4 mr-2 text-muted-foreground" />
                <SelectValue placeholder={t('templates.allTemplates')} />
              </div>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">{t('templates.allTemplates')}</SelectItem>
              <SelectItem value="container">{t('templates.containerTemplates')}</SelectItem>
              <SelectItem value="single">{t('templates.singleTemplates')}</SelectItem>
            </SelectContent>
          </Select>
          <Button onClick={() => navigate('/juh/article-management/templates/new')}>
            <PlusCircle className="size-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('templates.addTemplate')}</span>
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : !isLoading ? (
              <EmptyTableRow colSpan={columns.length} />
            ) : (
              <TableLoadingCell colSpan={columns.length} />
            )}
          </TableBody>
        </Table>

        <DataTablePagination table={table} />
      </div>

      <AlertDialog open={!!templateToDelete} onOpenChange={(open) => !open && setTemplateToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('templates.deleteTemplate')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('templates.deleteTemplateConfirmation', { name: templateToDelete?.name || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteTemplate}>{t('common.delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
