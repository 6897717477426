import AutoFormObject from '@/shared/components/ui/auto-form/fields/object';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';

import type { FieldConfig, FieldConfigItem } from '../ui/auto-form/types';

type MetadataAutoFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: z.ZodObject<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  path?: string[];
  disabled?: boolean;
  metadataSchema?: JsonSchemaType;
  isCustomer?: boolean;
};

type ExtendedFieldConfigItem = FieldConfigItem & {
  metadataSchema?: JsonSchemaType;
  isCustomer?: boolean;
};

export function MetadataAutoForm({ schema, form, path, disabled, metadataSchema, isCustomer }: MetadataAutoFormProps) {
  const fieldConfig: FieldConfig<z.infer<typeof schema>> = {
    '*': {
      inputProps: {
        disabled,
      },
      metadataSchema,
      isCustomer,
    } as ExtendedFieldConfigItem,
  };

  return (
    <AutoFormObject schema={schema} form={form} path={path} fieldConfig={fieldConfig} metadataSchema={metadataSchema} />
  );
}
