import type { Notification } from '@johanniter-offshore/backend';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

type NotificationsResponse = {
  docs: Notification[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
};

export const useInfiniteNotifications = (params?: {
  where?: Where;
  sort?: keyof Notification | `-${keyof Notification}`;
  limit?: number;
  disabled?: boolean;
}) => {
  return useInfiniteQuery<
    NotificationsResponse,
    Error,
    { pages: NotificationsResponse[] },
    [string, typeof params],
    number
  >({
    queryKey: ['notifications-infinite', params],
    enabled: !params?.disabled,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam: number) => {
      if (lastPage.page < lastPage.totalPages) return lastPageParam + 1;
      return undefined;
    },
    queryFn: async ({ pageParam }) => {
      const response = await payloadClient.find({
        collection: 'notifications',
        ...params,
        page: pageParam,
      });
      return response as NotificationsResponse;
    },
  });
};

export const useNotifications = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Notification | `-${keyof Notification}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['notifications', params],
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'notifications',
        ...params,
      });
      return response;
    },
  });
};

export const useUnreadNotificationsCount = () => {
  return useQuery({
    queryKey: ['notifications-unread-count'],
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'notifications',
        where: {
          status: {
            equals: 'unread',
          },
        },
        limit: 1,
        depth: 0,
      });
      return response.totalDocs;
    },
  });
};

export const useUpdateNotificationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, status }: { id: string; status: 'read' | 'archived' }) => {
      const response = await payloadClient.updateById({
        collection: 'notifications',
        id,
        data: { status },
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      queryClient.invalidateQueries({ queryKey: ['notifications-infinite'] });
      queryClient.invalidateQueries({ queryKey: ['notifications-unread-count'] });
    },
  });
};
