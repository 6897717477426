import { useCategories, useCreateTemplate, useTemplate, useUploadMedia } from '@/core/api';
import { PageHeader } from '@/shared/components/layout/page-header';
import { formFieldsToJsonSchema, jsonSchemaToFormFields } from '@/shared/components/metadata';
import type { Media, Template } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { TemplateForm } from '../components/template-form';
import { useTemplateForms } from '../hooks/use-template-forms';

interface TemplateFile {
  file: string | Media;
  name: string;
  description?: string | null;
  id?: string | null;
}

export function CreateTemplate() {
  const { t } = useIntl();
  const createTemplate = useCreateTemplate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const copyFromId = searchParams.get('copy');
  const { data: templateToCopy } = useTemplate(copyFromId || '', { disabled: !copyFromId });
  const { data: categoriesData } = useCategories({ limit: 0 });
  const uploadMedia = useUploadMedia();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isJuhAdmin,
    detailsForm,
    categoryForm,
    inspectionsForm,
    metadataForm,
    containerAndChildTemplatesForm,
    templateFilesForm,
    validateForms,
  } = useTemplateForms();

  // Pre-fill forms when copying a template
  useEffect(() => {
    if (templateToCopy) {
      detailsForm.reset({
        name: `${templateToCopy.name} (${t('common.copy')})`,
        description: templateToCopy.description || undefined,
      });

      categoryForm.reset({
        category: templateToCopy.category as string | null,
      });

      inspectionsForm.reset({
        inspections:
          templateToCopy.inspections?.map((inspection) => ({
            id: null,
            name: inspection.name,
            interval: inspection.interval,
            checklist: inspection.checklist?.map((item) => ({
              item: item.item,
              order: item.order,
              id: null,
            })),
          })) || [],
      });

      metadataForm.reset({
        metadata: templateToCopy.metadata ? jsonSchemaToFormFields(templateToCopy.metadata) : [],
      });

      containerAndChildTemplatesForm.reset({
        isContainer: templateToCopy.isContainer || false,
        childTemplates:
          templateToCopy.childTemplates?.map((template) => ({
            template: typeof template.template === 'string' ? template.template : template.template.id,
            quantity: template.quantity,
            order: template.order || undefined,
          })) || [],
      });

      // Copy files
      const files = templateToCopy.files as TemplateFile[] | undefined;
      templateFilesForm.reset({
        files:
          files?.map((file) => ({
            name: file.name,
            description: file.description || '',
            file: typeof file.file === 'string' ? file.file : file.file.id,
          })) || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToCopy, t]);

  const onSubmit = async () => {
    if (!(await validateForms())) {
      return;
    }

    setIsSubmitting(true);

    try {
      const detailsData = detailsForm.getValues();
      const categoryData = categoryForm.getValues();
      const inspectionsData = inspectionsForm.getValues();
      const metadataData = metadataForm.getValues();
      const containerAndChildTemplatesData = containerAndChildTemplatesForm.getValues();
      const templateFilesData = templateFilesForm.getValues();

      const jsonSchema = formFieldsToJsonSchema(metadataData.metadata);

      // Handle files - both new uploads and existing files
      const newFiles = templateFilesData.files.filter(
        (file): file is { name: string; description?: string; file: File } => file.file instanceof File,
      );
      const existingFiles = templateFilesData.files.filter(
        (file): file is { name: string; description?: string; file: string } => typeof file.file === 'string',
      );

      // Upload new files
      const uploadedFiles = await Promise.all(
        newFiles.map(async (fileData) => {
          if (!fileData.file) {
            throw new Error('File is missing');
          }
          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      const payload: Partial<Template> = {
        ...detailsData,
        ...categoryData,
        ...inspectionsData,
        metadata: jsonSchema,
        isContainer: containerAndChildTemplatesData.isContainer,
        childTemplates: containerAndChildTemplatesData.isContainer
          ? containerAndChildTemplatesData.childTemplates
              ?.filter((template) => template.template && template.quantity)
              .map((template, index) => ({ ...template, order: index }))
          : undefined,
        files: [...existingFiles, ...uploadedFiles],
      };

      await createTemplate.mutateAsync(payload);
      toast.success(t('templates.templateCreatedDescription'));
      navigate('/juh/article-management/templates');
    } catch (error) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payloadError = error as { response?: { errors?: Array<{ message: string; data?: any }> } };
        const maxDepthError = payloadError.response?.errors?.find((e) => e.message === 'MaxTemplateDepthExceeded');

        if (maxDepthError) {
          toast.error(t('templates.maxDepthErrorTitle'), {
            description: t('templates.maxDepthErrorDescription'),
            duration: 25000,
            closeButton: true,
            dismissible: true,
          });

          const errorData = maxDepthError.data;
          const childTemplateId = errorData?.childTemplate;
          const index = containerAndChildTemplatesForm
            .getValues()
            .childTemplates?.findIndex((t) => t.template === childTemplateId);
          if (index !== undefined && index !== -1) {
            containerAndChildTemplatesForm.setError(`childTemplates.${index}.template`, {
              type: 'manual',
              message: t('templates.maxDepthErrorDescription'),
            });
          }
          return;
        }
      }
      toast.error(t('templates.errorSavingTemplateDescription'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageHeader
        title={copyFromId ? t('templates.copyTemplate') : t('templates.newTemplate')}
        backHref="/juh/article-management/templates"
        primaryAction={{
          label: t('common.create'),
          loadingLabel: t('common.creating'),
          onClick: onSubmit,
          disabled: isSubmitting,
          isLoading: isSubmitting,
        }}
        secondaryAction={{
          label: t('common.discard'),
          onClick: () => navigate('/juh/article-management/templates'),
          variant: 'outline',
        }}
      />

      <TemplateForm
        detailsForm={detailsForm}
        categoryForm={categoryForm}
        inspectionsForm={inspectionsForm}
        metadataForm={metadataForm}
        containerAndChildTemplatesForm={containerAndChildTemplatesForm}
        templateFilesForm={templateFilesForm}
        categoriesData={categoriesData}
        isJuhAdmin={isJuhAdmin}
      />
    </>
  );
}
