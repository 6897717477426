import { juhRoutes } from '@/core/router';
import { Header, Sidebar } from '@/shared/components/layout';
import '@/shared/components/layout';
import { SidebarInset, SidebarProvider } from '@/shared/components/ui';
import { Outlet } from 'react-router-dom';

export function JuhLayout() {
  return (
    <SidebarProvider>
      <Sidebar routes={juhRoutes} showCertificates />
      <SidebarInset>
        <Header routes={juhRoutes} />
        <main className="flex flex-1 flex-col gap-4 p-4 pt-3 space-y-6">
          <div className="container mx-auto pt-3 space-y-6">
            <Outlet />
          </div>
        </main>
      </SidebarInset>
    </SidebarProvider>
  );
}
