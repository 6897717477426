import { useCreateMetadataGroup, useDeleteMetadataGroup, useMetadataGroups, useUpdateMetadataGroup } from '@/core/api';
import { useAuth } from '@/core/auth';
import { TabNavPortal } from '@/shared/components/layout';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui';
import type { MetadataGroup } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { MetadataGroupForm, type MetadataGroupFormValues, MetadataGroupTable } from '../components';

export function ManageMetadataGroups() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const [selectedGroup, setSelectedGroup] = useState<MetadataGroup | undefined>(undefined);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const { data: groupsData } = useMetadataGroups({
    sort: 'name',
  });

  const createGroup = useCreateMetadataGroup();
  const updateGroup = useUpdateMetadataGroup();
  const deleteGroup = useDeleteMetadataGroup();

  const handleEditGroup = (group: MetadataGroup) => {
    setSelectedGroup(group);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedGroup(undefined);

    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 200);
  };

  const handleSubmit = async (data: MetadataGroupFormValues) => {
    if (selectedGroup) {
      await updateGroup.mutateAsync({
        id: selectedGroup.id,
        data,
      });
      toast.success(t('metadata.groups.groupUpdatedSuccessfully'));
      handleCloseEditDialog();
    } else {
      await createGroup.mutateAsync(data);
      setIsCreateDialogOpen(false);
      toast.success(t('metadata.groups.groupCreatedSuccessfully'));
    }
  };

  const handleDelete = async (group: MetadataGroup) => {
    await deleteGroup.mutateAsync(group.id);
    toast.success(t('metadata.groups.groupDeletedSuccessfully'));
  };

  return (
    <div className="space-y-6">
      <TabNavPortal>
        <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
          <DialogTrigger asChild>
            <Button disabled={!isJuhAdmin}>
              <PlusCircle className="size-4 mr-2" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('metadata.groups.addGroup')}</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('metadata.groups.createGroup')}</DialogTitle>
              <DialogDescription>{t('metadata.groups.groupDetailsDescription')}</DialogDescription>
            </DialogHeader>
            <MetadataGroupForm onSubmit={handleSubmit} />
          </DialogContent>
        </Dialog>
      </TabNavPortal>

      <MetadataGroupTable
        groups={groupsData?.docs ?? []}
        onEdit={handleEditGroup}
        onDelete={handleDelete}
        isActionDisabled={!isJuhAdmin}
      />

      <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('metadata.groups.editGroup')}</DialogTitle>
            <DialogDescription>{t('metadata.groups.groupDetailsDescription')}</DialogDescription>
          </DialogHeader>
          <MetadataGroupForm group={selectedGroup} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
