import type { PayloadApiError } from '@/core/api/client';
import { useAuth } from '@/core/auth';
import {
  Badge,
  Button,
  DatePicker,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  ScrollArea,
  Textarea,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

import { deploymentSchema } from '../schema/deployment-schema';
import type { DeploymentFormValues } from '../schema/deployment-schema';

type DeploymentErrorType =
  | 'DeploymentStartAfterEnd'
  | 'DeploymentEndBeforeStart'
  | 'DeploymentStartBeforeParent'
  | 'DeploymentEndAfterParent'
  | 'DeploymentsOverlap'
  | 'InvalidDeploymentDate'
  | 'CrewChangeDateOutsideDeployment'
  | 'InvalidCrewChangeDate'
  | 'DeploymentStartBeforeOrder'
  | 'DeploymentEndAfterOrder';

interface DeploymentApiError extends Omit<PayloadApiError, 'response'> {
  response: {
    errors: Array<{
      message: DeploymentErrorType;
    }>;
  };
}

interface DeploymentDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (data: DeploymentFormValues) => Promise<void>;
  defaultValues?: DeploymentFormValues;
  parentStartDate: string;
  changeRequest?: {
    id: string;
    changes: DeploymentFormValues;
    requestedBy: {
      name: string;
    };
    requestedAt: string;
  };
  onAcceptChangeRequest?: (id: string) => Promise<void>;
  onRejectChangeRequest?: (id: string) => Promise<void>;
}

export function DeploymentDrawer({
  open,
  onOpenChange,
  onSubmit,
  defaultValues,
  parentStartDate,
  changeRequest,
  onAcceptChangeRequest,
  onRejectChangeRequest,
}: DeploymentDrawerProps) {
  const { t } = useIntl();
  const { user } = useAuth();
  const isEditing = Boolean(defaultValues?.id);
  const [changeRequestError, setChangeRequestError] = useState<DeploymentApiError | undefined>();
  const isCustomerUser = user?.role === 'customer-admin' || user?.role === 'customer-employee';
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';

  const form = useForm<DeploymentFormValues>({
    resolver: zodResolver(deploymentSchema),
    defaultValues: defaultValues || {
      id: null,
      startDate: '',
      endDate: '',
      crewChange: null,
      description: '',
    },
  });

  // Check if form values have changed from the original deployment
  const hasChanges = useMemo(() => {
    if (!defaultValues) return true; // New deployment always has changes

    const formValues = form.getValues();
    return (
      formValues.title !== defaultValues.title ||
      formValues.startDate !== defaultValues.startDate ||
      formValues.endDate !== defaultValues.endDate ||
      formValues.crewChange !== defaultValues.crewChange ||
      formValues.description !== defaultValues.description
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch(), defaultValues]);

  // Reset form when defaultValues change or drawer is opened/closed
  useEffect(() => {
    if (open) {
      form.reset(
        defaultValues || {
          id: null,
          startDate: '',
          endDate: '',
          crewChange: null,
          description: '',
        },
      );
    }
  }, [form, defaultValues, open]);

  const handleSubmit = async (data: DeploymentFormValues) => {
    try {
      await onSubmit(data);
      toast.success(
        t(
          isCustomerUser
            ? 'orders.deploymentChangeRequested'
            : isEditing
              ? 'orders.deploymentUpdated'
              : 'orders.deploymentAdded',
        ),
      );
    } catch (err) {
      const apiError = err as DeploymentApiError;

      // Get the error message from the API error structure
      const errorType = apiError.response?.errors?.[0]?.message;

      // Set form errors based on API error
      if (errorType) {
        switch (errorType) {
          case 'DeploymentStartAfterEnd':
          case 'DeploymentEndBeforeStart':
            form.setError('startDate', { message: t(`validation.${errorType}`) });
            form.setError('endDate', { message: t(`validation.${errorType}`) });
            break;
          case 'DeploymentStartBeforeParent':
            form.setError('startDate', { message: t('validation.DeploymentStartBeforeParent') });
            break;
          case 'DeploymentEndAfterParent':
            form.setError('endDate', { message: t('validation.DeploymentEndAfterParent') });
            break;
          case 'DeploymentStartBeforeOrder':
            form.setError('startDate', { message: t('validation.DeploymentStartBeforeOrder') });
            break;
          case 'DeploymentEndAfterOrder':
            form.setError('endDate', { message: t('validation.DeploymentEndAfterOrder') });
            break;
          case 'DeploymentsOverlap':
            form.setError('startDate', { message: t('validation.DeploymentsOverlap') });
            form.setError('endDate', { message: t('validation.DeploymentsOverlap') });
            break;
          case 'InvalidDeploymentDate':
            form.setError('startDate', { message: t('validation.InvalidDeploymentDate') });
            form.setError('endDate', { message: t('validation.InvalidDeploymentDate') });
            break;
          case 'CrewChangeDateOutsideDeployment':
            form.setError('crewChange', { message: t('validation.CrewChangeDateOutsideDeployment') });
            break;
          case 'InvalidCrewChangeDate':
            form.setError('crewChange', { message: t('validation.InvalidCrewChangeDate') });
            break;
          default:
            toast.error(t('common.errorSaving'));
        }
      } else {
        toast.error(t('common.errorSaving'));
      }
    }
  };

  const handleAcceptChangeRequest = async (id: string) => {
    try {
      setChangeRequestError(undefined);
      await onAcceptChangeRequest?.(id);
    } catch (err) {
      setChangeRequestError(err as DeploymentApiError);
    }
  };

  const renderChangeRequestCard = () => {
    if (!changeRequest) return null;

    return (
      <div className="flex flex-col gap-2">
        <div className="rounded-lg border p-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Badge variant="outline" className="bg-primary/5 text-primary">
                {t('orders.changeRequest')}
              </Badge>
              <span>
                {t('orders.changeRequestBy', {
                  name: changeRequest.requestedBy.name,
                  date: DateTime.fromISO(changeRequest.requestedAt).toFormat('dd.MM.yyyy - HH:mm'),
                })}
              </span>
            </div>
            {isJuhUser && (
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => onRejectChangeRequest?.(changeRequest.id)}
                  type="button"
                >
                  {t('common.reject')}
                </Button>
                <Button size="sm" onClick={() => handleAcceptChangeRequest(changeRequest.id)} type="button">
                  {t('common.accept')}
                </Button>
              </div>
            )}
          </div>

          {changeRequestError?.response?.errors?.[0]?.message && (
            <div className="mb-4 rounded-md bg-destructive/10 p-3 text-sm text-destructive">
              {t(`validation.${changeRequestError.response.errors[0].message}`)}
            </div>
          )}

          <div className="space-y-4">
            <div className="space-y-2">
              <Label>{t('orders.title')}</Label>
              <Input type="text" value={changeRequest.changes.title} disabled />
            </div>
            <div className="space-y-2">
              <Label>{t('orders.startDate')}</Label>
              <DatePicker date={new Date(changeRequest.changes.startDate)} setDate={() => {}} disabled />
            </div>
            <div className="space-y-2">
              <Label>{t('orders.endDate')}</Label>
              <DatePicker date={new Date(changeRequest.changes.endDate)} setDate={() => {}} disabled />
            </div>
            <div className="space-y-2">
              <Label>{t('orders.crewChange')}</Label>
              <DatePicker
                date={changeRequest.changes.crewChange ? new Date(changeRequest.changes.crewChange) : null}
                setDate={() => {}}
                disabled
              />
            </div>
            <div className="space-y-2">
              <Label>{t('orders.description')}</Label>
              <Textarea rows={4} value={changeRequest.changes.description} disabled />
            </div>
          </div>
        </div>
        {isCustomerUser && (
          <p className="text-xs text-muted-foreground">({t('orders.changeRequestReplacementDescription')})</p>
        )}
      </div>
    );
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange} direction="right" dismissible={false}>
      <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none lg:w-2/5 lg:min-w-[460px]">
        <ScrollArea className="h-screen">
          <DrawerHeader>
            <DrawerTitle>
              {isCustomerUser
                ? t(isEditing ? 'orders.requestDeploymentEdit' : 'orders.requestNewDeployment')
                : t(isEditing ? 'orders.editDeployment' : 'orders.addDeployment')}
            </DrawerTitle>
            <DrawerDescription>
              {isCustomerUser ? t('orders.deploymentChangeRequestDescription') : t('orders.deploymentFormDescription')}
            </DrawerDescription>
          </DrawerHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              {changeRequest && <div className="px-4">{renderChangeRequestCard()}</div>}
              <div className="space-y-4 p-4">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('orders.title')}</FormLabel>
                      <FormControl>
                        <input
                          type="text"
                          className={`
                            flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background
                            file:border-0 file:bg-transparent file:text-sm file:font-medium
                            placeholder:text-muted-foreground
                            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2
                            disabled:cursor-not-allowed disabled:opacity-50
                          `}
                          {...field}
                          disabled={form.formState.isSubmitting}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('orders.startDate')}</FormLabel>
                      <FormControl>
                        <DatePicker
                          date={field.value ? new Date(field.value) : undefined}
                          setDate={(date) => field.onChange(date?.toISOString() || null)}
                          placeholder={t('orders.chooseStartDate')}
                          defaultMonth={field.value ? new Date(field.value) : new Date(parentStartDate)}
                          disabled={form.formState.isSubmitting}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('orders.endDate')}</FormLabel>
                      <FormControl>
                        <DatePicker
                          date={field.value ? new Date(field.value) : undefined}
                          setDate={(date) => field.onChange(date?.toISOString() || null)}
                          placeholder={t('orders.chooseEndDate')}
                          defaultMonth={field.value ? new Date(field.value) : new Date(parentStartDate)}
                          disabled={form.formState.isSubmitting}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="crewChange"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('orders.crewChange')}</FormLabel>
                      <FormControl>
                        <DatePicker
                          date={field.value ? new Date(field.value) : undefined}
                          setDate={(date) => field.onChange(date?.toISOString() || null)}
                          placeholder={t('orders.chooseCrewChange')}
                          defaultMonth={field.value ? new Date(field.value) : new Date(parentStartDate)}
                          disabled={form.formState.isSubmitting}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('orders.description')}</FormLabel>
                      <FormControl>
                        <Textarea rows={4} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DrawerFooter>
                <Button
                  type="submit"
                  disabled={
                    form.formState.isSubmitting || (isCustomerUser && isEditing && !hasChanges) // Only disable for customer change requests
                  }
                >
                  {isCustomerUser
                    ? t('orders.requestDeploymentChange')
                    : isEditing
                      ? t('orders.updateDeployment')
                      : t('orders.addDeployment')}
                </Button>
                <DrawerClose asChild>
                  <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
                    {t('common.cancel')}
                  </Button>
                </DrawerClose>
              </DrawerFooter>
            </form>
          </Form>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
