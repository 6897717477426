import { useChangeRequests } from '@/core/api/change-requests';
import { useInfiniteNotifications, useUpdateNotificationStatus } from '@/core/api/notifications';
import { useOrders } from '@/core/api/orders';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout/page-heading';
import { TabNav } from '@/shared/components/layout/tab-nav';
import { Badge } from '@/shared/components/ui/badge';
import { Button } from '@/shared/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/components/ui/card';
import type { Notification, User } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Archive, Eye, Loader2, MailCheck } from 'lucide-react';
import { DateTime } from 'luxon';
import { Link, useLocation } from 'react-router-dom';

const NOTIFICATIONS_PER_PAGE = 10;

type NotificationContentType = {
  title: string;
  message: string;
};

const EmptyCard = ({ message }: { message: string }) => (
  <Card>
    <CardContent className="flex min-h-[112px] items-center justify-center">
      <p className="text-sm text-muted-foreground">{message}</p>
    </CardContent>
  </Card>
);

const LoadMoreButton = ({ isFetchingNextPage, onClick }: { isFetchingNextPage: boolean; onClick: () => void }) => {
  const { t } = useIntl();

  return (
    <div className="flex justify-center">
      <Button variant="outline" onClick={onClick} disabled={isFetchingNextPage} className="min-w-[200px]">
        {isFetchingNextPage ? (
          <>
            <Loader2 className="mr-2 size-4 animate-spin" />
            {t('activity.loadingMore')}
          </>
        ) : (
          t('activity.loadMore')
        )}
      </Button>
    </div>
  );
};

const getNotificationContent = (
  type: string,
  t: (key: string, params?: Record<string, string>) => string,
): NotificationContentType => {
  switch (type) {
    case 'order-created':
      return {
        title: t('notifications.types.orderCreated.title'),
        message: t('notifications.types.orderCreated.message'),
      };
    case 'deployment-planning-change-requested':
      return {
        title: t('notifications.types.deploymentPlanningChangeRequested.title'),
        message: t('notifications.types.deploymentPlanningChangeRequested.message'),
      };
    case 'deployment-planning-change-confirmed':
      return {
        title: t('notifications.types.deploymentPlanningChangeConfirmed.title'),
        message: t('notifications.types.deploymentPlanningChangeConfirmed.message'),
      };
    case 'deployment-planning-change-rejected':
      return {
        title: t('notifications.types.deploymentPlanningChangeRejected.title'),
        message: t('notifications.types.deploymentPlanningChangeRejected.message'),
      };
    default:
      return {
        title: '',
        message: '',
      };
  }
};

const useNotificationLink = (notification: Notification, user: User | null) => {
  // Query the change request for deployment planning notifications
  const { data: changeRequest } = useChangeRequests({
    where: {
      id: {
        equals: notification.targetItem,
      },
    },
    limit: 1,
    disabled: !notification.type.startsWith('deployment-planning-'),
  });

  // Query the order using the change request's item field
  const { data: orders } = useOrders({
    where: changeRequest
      ? {
          'deployments.id': {
            equals: changeRequest.docs[0]?.item,
          },
        }
      : undefined,
    limit: 1,
    disabled: !changeRequest?.docs.length,
  });

  const getLink = () => {
    if (!user) return '';

    // Base URL based on user role
    const baseUrl = user.role?.startsWith('juh-') ? '/juh/order-management/orders' : '/c/my-orders';

    // For order-created notifications, we can directly use the target item
    if (notification.type === 'order-created') {
      return `${baseUrl}/${notification.targetItem}`;
    }

    // For deployment planning notifications, we need to find the parent order
    if (notification.type.startsWith('deployment-planning-')) {
      if (!orders?.docs.length) {
        return baseUrl;
      }

      const subOrder = orders.docs[0];
      const parentId = subOrder.parent as string;

      if (!parentId) {
        return baseUrl;
      }

      return `${baseUrl}/${parentId}/sub-orders/${subOrder.id}/deployment-planning`;
    }

    // Fallback to base URL if we can't determine the specific path
    return baseUrl;
  };

  return getLink();
};

const NotificationCard = ({
  notification,
  content,
  onUpdateStatus,
  showArchiveButton,
}: {
  notification: Notification;
  content: NotificationContentType;
  onUpdateStatus: (status: 'read' | 'archived') => void;
  showArchiveButton: boolean;
}) => {
  const { t } = useIntl();
  const { locale } = useIntl();
  const { user } = useAuth();
  const isUnread = notification.status === 'unread';
  const link = useNotificationLink(notification, user);

  // Query change request status for deployment planning notifications
  const { data: changeRequest } = useChangeRequests({
    where: {
      id: {
        equals: notification.targetItem,
      },
    },
    limit: 1,
    disabled: !notification.type.startsWith('deployment-planning-'),
  });

  const changeRequestStatus = changeRequest?.docs[0]?.status;

  return (
    <Card>
      <CardHeader className="border-b-0">
        <div className="flex items-start justify-between gap-4">
          <div>
            <div className="flex items-center gap-2">
              <CardTitle className="text-base">{content.title}</CardTitle>
              {changeRequestStatus && (
                <Badge
                  variant={
                    changeRequestStatus === 'pending'
                      ? 'default'
                      : changeRequestStatus === 'approved'
                        ? 'secondary'
                        : 'destructive'
                  }
                >
                  {t(
                    `orders.changeRequest${changeRequestStatus.charAt(0).toUpperCase()}${changeRequestStatus.slice(1)}`,
                  )}
                </Badge>
              )}
            </div>
            <CardDescription className="mt-1.5">{content.message}</CardDescription>
          </div>
          <div className="flex items-center gap-2">
            {isUnread && (
              <Button variant="ghost" size="icon" onClick={() => onUpdateStatus('read')}>
                <MailCheck className="size-4" />
                <span className="sr-only">{t('notifications.actions.markAsRead')}</span>
              </Button>
            )}
            <Button variant="ghost" size="icon" asChild>
              <Link to={link}>
                <Eye className="size-4" />
                <span className="sr-only">{t('notifications.actions.view')}</span>
              </Link>
            </Button>
            {showArchiveButton && (
              <Button variant="ghost" size="icon" onClick={() => onUpdateStatus('archived')}>
                <Archive className="size-4" />
                <span className="sr-only">{t('notifications.actions.archive')}</span>
              </Button>
            )}
          </div>
        </div>
        <div className="mt-2 text-xs text-muted-foreground">
          {DateTime.fromISO(notification.createdAt).toLocaleString(DateTime.DATETIME_MED, {
            locale,
          })}
        </div>
      </CardHeader>
    </Card>
  );
};

export const Notifications = () => {
  const { t } = useIntl();
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop() || 'unread';

  const {
    data: notifications,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNotifications({
    sort: '-createdAt',
    where: {
      status: {
        equals: currentTab,
      },
    },
    limit: NOTIFICATIONS_PER_PAGE,
  });

  const { mutate: updateStatus } = useUpdateNotificationStatus();

  const tabs = [
    {
      value: 'unread',
      label: t('notifications.tabs.unread'),
    },
    {
      value: 'read',
      label: t('notifications.tabs.read'),
    },
    {
      value: 'archived',
      label: t('notifications.tabs.archived'),
    },
  ];

  const allNotifications = notifications?.pages.flatMap((page) => page.docs) ?? [];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <PageHeading>{t('common.notifications')}</PageHeading>
      </div>

      <TabNav tabs={tabs} basePath={location.pathname.replace(/\/(unread|read|archived)?$/, '')} />

      <div className="mt-6 space-y-4">
        {!notifications ? (
          <EmptyCard message={t('common.loading')} />
        ) : allNotifications.length === 0 ? (
          <EmptyCard message={t('notifications.empty')} />
        ) : (
          <>
            {allNotifications.map((notification) => {
              const content = getNotificationContent(notification.type, t);

              return (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  content={content}
                  onUpdateStatus={(status) => updateStatus({ id: notification.id, status })}
                  showArchiveButton={currentTab !== 'archived'}
                />
              );
            })}

            {hasNextPage && <LoadMoreButton isFetchingNextPage={isFetchingNextPage} onClick={() => fetchNextPage()} />}
          </>
        )}
      </div>
    </div>
  );
};
