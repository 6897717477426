import { PayloadApiError } from '@/core/api';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared/components/ui';
import z from '@/shared/utils/zod-translations';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

interface LoginFormProps {
  onSubmit: (values: { email: string; password: string }) => Promise<void>;
  isLoading: boolean;
  showForgotPassword?: boolean;
  defaultEmail?: string;
  defaultPassword?: string;
}

export function LoginForm({
  onSubmit,
  isLoading,
  showForgotPassword = true,
  defaultEmail = '',
  defaultPassword = '',
}: LoginFormProps) {
  const { t } = useIntl();

  const formSchema = z.object({
    email: z.string().min(1).email(),
    password: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: defaultEmail,
      password: defaultPassword,
    },
  });

  useEffect(() => {
    const subscription = form.watch(() => {
      form.clearErrors('root');
    });
    return () => subscription.unsubscribe();
  }, [form]);

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      await onSubmit(values);
    } catch (error) {
      if (error instanceof PayloadApiError) {
        if (error.statusCode === 401) {
          form.setError('root', { message: t('auth.invalidEmailOrPassword') });
        }
      } else {
        form.setError('root', { message: t('errors.generalError') });
      }
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('auth.email')}</FormLabel>
              <FormControl>
                <Input placeholder="name@beispiel.de" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('auth.password')}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {showForgotPassword && (
          <div className="flex items-center justify-between">
            <Link to="/auth/forgot-password" className="text-sm underline">
              {t('auth.forgotPassword')}
            </Link>
          </div>
        )}

        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        <Button type="submit" className="w-full" disabled={isLoading}>
          {isLoading ? t('auth.loggingIn') : t('auth.login')}
        </Button>
      </form>
    </Form>
  );
}
