import type { ReactNode } from 'react';
import type { Id } from 'react-calendar-timeline';
import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline';

import './timeline.scss';

export interface TimelineGroup {
  id: Id;
  title: ReactNode;
  root?: boolean;
  height?: number;
}

export interface TimelineItem {
  id: Id;
  group: Id;
  title: ReactNode;
  start_time: number;
  end_time: number;
}

interface TimelineViewProps {
  groups: TimelineGroup[];
  items: TimelineItem[];
  startTime: number;
  endTime: number;
  minZoom?: number;
  maxZoom?: number;
  lineHeight?: number;
  sidebarWidth?: number;
  stackItems?: boolean;
  horizontalLineClassNamesForGroup?: (group: TimelineGroup) => string[];
  onItemClick?: (itemId: Id) => void;
}

export function TimelineView({
  groups,
  items,
  startTime,
  endTime,
  minZoom = 24 * 60 * 60 * 1000, // 1 day
  maxZoom = 365 * 24 * 60 * 60 * 1000, // 1 year
  lineHeight = 40,
  sidebarWidth = 300,
  stackItems = true,
  horizontalLineClassNamesForGroup,
  onItemClick,
}: TimelineViewProps) {
  return (
    <div className="rounded-lg border border-border overflow-hidden relative z-0">
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={startTime}
        defaultTimeEnd={endTime}
        canMove={false}
        canResize={false}
        minZoom={minZoom}
        maxZoom={maxZoom}
        lineHeight={lineHeight}
        sidebarWidth={sidebarWidth}
        stackItems={stackItems}
        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
        onItemClick={onItemClick}
      >
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => {
              const customStyles = {
                ...styles,
                width: '1px',
                backgroundColor: 'rgba(var(--primary))',
              };
              return <div style={customStyles} />;
            }}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    </div>
  );
}
