import { FileUploadCard } from '@/shared/components/uploads';
import { useIntl } from '@tiny-intl/react';

interface ArticleFilesCardProps {
  readOnly?: boolean;
}

export function ArticleFilesCard({ readOnly = false }: ArticleFilesCardProps) {
  const { t } = useIntl();

  return (
    <FileUploadCard
      disabled={readOnly}
      title={t('articles.articleFiles')}
      description={t('articles.articleFilesDescription')}
      fieldArrayName="articleFiles"
    />
  );
}
