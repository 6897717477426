import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { MetadataGroup } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface MetadataGroupFormProps {
  group?: MetadataGroup;
  onSubmit: (data: MetadataGroupFormValues) => Promise<void>;
}

export type MetadataGroupFormValues = z.infer<typeof metadataGroupFormSchema>;

const metadataGroupFormSchema = z.object({
  name: z.string().min(1),
});

export const MetadataGroupForm = ({ group, onSubmit }: MetadataGroupFormProps) => {
  const { t } = useIntl();

  const form = useForm<MetadataGroupFormValues>({
    resolver: zodResolver(metadataGroupFormSchema),
    defaultValues: {
      name: group?.name ?? '',
    },
  });

  const handleSubmit = async (data: MetadataGroupFormValues) => {
    await onSubmit(data);
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('common.name')}</FormLabel>
              <FormControl>
                <Input placeholder={t('metadata.groups.groupName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{group ? t('common.save') : t('common.create')}</Button>
      </form>
    </Form>
  );
};
