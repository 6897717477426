import { MetadataAutoForm } from '@/shared/components/metadata';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/components/ui';
import { Placeholder } from '@/shared/components/ui/placeholder';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Package } from 'lucide-react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';

interface ArticleMetadataCardProps {
  schema: z.ZodObject<z.ZodRawShape>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  templateSelected: boolean;
  isCustomer?: boolean;
  metadataSchema?: JsonSchemaType;
}

export function ArticleMetadataCard({
  schema,
  form,
  templateSelected,
  isCustomer = false,
  metadataSchema,
}: ArticleMetadataCardProps) {
  const { t } = useIntl();
  const hasMetadataFields = Object.keys(schema.shape).length > 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.articleMetadata')}</CardTitle>
        <CardDescription>{t('articles.articleMetadataDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!templateSelected ? (
          <Placeholder
            icon={Package}
            title={t('articles.assignment.selectTemplate')}
            description={t('articles.selectTemplateForMetadata')}
          />
        ) : hasMetadataFields ? (
          <MetadataAutoForm schema={schema} form={form} metadataSchema={metadataSchema} isCustomer={isCustomer} />
        ) : (
          <div className="text-sm text-muted-foreground">({t('metadata.noTemplateMetadata')})</div>
        )}
      </CardContent>
    </Card>
  );
}
