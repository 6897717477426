import { PageHeading, TabNav } from '@/shared/components/layout';
import { useIntl } from '@tiny-intl/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function MetadataLayout() {
  const { t } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    {
      value: 'article',
      label: t('metadata.articleMetadata'),
    },
    {
      value: 'order',
      label: t('metadata.orderMetadata'),
    },
    {
      value: 'groups',
      label: t('metadata.groups.groups'),
    },
  ];

  // If we're at the root path, redirect to the default tab
  if (location.pathname === '/juh/settings/metadata') {
    navigate('/juh/settings/metadata/article');
    return null;
  }

  return (
    <div className="space-y-6">
      <PageHeading>{t('metadata.metadata')}</PageHeading>
      <TabNav tabs={tabs} basePath="/juh/settings/metadata" />
      <Outlet />
    </div>
  );
}
