import { useUnreadNotificationsCount } from '@/core/api/notifications';
import { useAuth } from '@/core/auth';
import {
  Sidebar as ShadcnSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/shared/components/ui';
import type { RouteProp } from '@/shared/types/route-prop';
import { useIntl } from '@tiny-intl/react';
import { Bell } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

import { CertificatesButton } from './certificates-button';
import { Logo } from './logo';
import { NavUser } from './nav-user';
import { SidebarLink } from './sidebar-link';

interface SidebarProps {
  routes: RouteProp[];
  showCertificates?: boolean;
}

export const Sidebar = ({ routes, showCertificates = false }: SidebarProps) => {
  const { t } = useIntl();
  const location = useLocation();
  const { data: unreadCount = 0 } = useUnreadNotificationsCount();
  const { user } = useAuth();

  const notificationsPath = user?.role?.startsWith('customer-') ? '/c/notifications' : '/juh/notifications';
  const isNotificationsActive = location.pathname.startsWith(notificationsPath);

  return (
    <ShadcnSidebar variant="floating">
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
      <SidebarContent className="flex flex-col">
        <SidebarGroup className="py-0">
          <SidebarMenuItem>
            <SidebarMenuButton asChild data-active={isNotificationsActive}>
              <Link to={notificationsPath} className="flex justify-between">
                <div className="flex items-center gap-2">
                  <Bell className="size-4" />
                  <span>{t('common.notifications')}</span>
                </div>

                {unreadCount > 0 && (
                  <span
                    className={`
                      flex h-4 min-w-4 items-center justify-center rounded-full bg-primary px-1 text-[10px] font-medium leading-none
                      text-primary-foreground
                    `}
                  >
                    {unreadCount}
                  </span>
                )}
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarGroup>

        <SidebarGroup className="pt-0">
          <SidebarGroupLabel>{t('common.navigation')}</SidebarGroupLabel>
          <SidebarMenu>
            {routes.map((route) => (
              <SidebarLink
                key={route.to}
                route={route}
                currentPath={location.pathname}
                isActive={location.pathname.startsWith(route.to)}
              />
            ))}
          </SidebarMenu>
        </SidebarGroup>

        <div className="mt-auto">
          <CertificatesButton show={showCertificates} />
        </div>
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </ShadcnSidebar>
  );
};
