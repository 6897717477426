import type { JsonSchemaType } from '@johanniter-offshore/backend';
import jsonSchemaToZod from 'json-schema-to-zod';
import { z } from 'zod';

/**
 * Checks if a metadata schema has any fields that are customer editable
 * @param schemas One or more JSON schemas to check for editable fields
 * @returns boolean indicating if any schema has editable fields
 */
export function hasEditableMetadata(...schemas: (JsonSchemaType | undefined)[]): boolean {
  return schemas.some(
    (schema) =>
      schema?.properties &&
      Object.values(schema.properties).some(
        (prop: { customerAccess?: string }) => prop.customerAccess === 'read-and-write',
      ),
  );
}

/**
 * Filters a metadata schema based on customer access permissions and converts it to a Zod schema
 * @param metadataSchema The original JSON schema
 * @param isJuhUser Whether the current user is a JUH user
 * @returns A Zod schema with filtered fields based on customer access
 */
export function createMetadataZodSchema(
  metadataSchema: JsonSchemaType | undefined,
  isJuhUser: boolean,
): z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny> {
  if (!metadataSchema) {
    return z.object({});
  }

  // For JUH users, use the original schema
  if (isJuhUser) {
    const zodSchemaString = jsonSchemaToZod(metadataSchema);
    const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
    return eval(transformedSchema);
  }

  // For customers, filter out fields with customerAccess: 'none'
  const filteredSchema = {
    ...metadataSchema,
    properties: Object.fromEntries(
      Object.entries(metadataSchema.properties).filter(([, prop]) => prop.customerAccess !== 'none'),
    ),
    required: metadataSchema.required.filter((field) => metadataSchema.properties[field]?.customerAccess !== 'none'),
    propertyOrder: metadataSchema.propertyOrder.filter(
      (field) => metadataSchema.properties[field]?.customerAccess !== 'none',
    ),
  };

  const zodSchemaString = jsonSchemaToZod(filteredSchema);
  const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
  return eval(transformedSchema);
}
