import { useUsers } from '@/core/api';
import type { Version } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal, PencilLine, Plus } from 'lucide-react';
import { DateTime } from 'luxon';
import type React from 'react';

export interface ActivityItem {
  id: string;
  type: 'created' | 'updated';
  timestamp: string;
  user: string | null;
  changes?: {
    field: string;
    from?: unknown;
    to?: unknown;
  }[];
}

export function ActivityIcon({ type }: { type: ActivityItem['type'] }) {
  if (type === 'created') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-green-500/20 text-green-500">
        <Plus className="size-3.5" />
      </div>
    );
  }
  return (
    <div className="flex size-6 items-center justify-center rounded-full bg-blue-500/20 text-blue-500">
      <PencilLine className="size-3.5" />
    </div>
  );
}

export interface ResolvedUserProps {
  userId: string;
}

export function ResolvedUser({ userId }: ResolvedUserProps) {
  const { t } = useIntl();
  const { data: usersData } = useUsers({
    where: {
      id: {
        equals: userId,
      },
    },
  });

  const user = usersData?.docs[0];

  if (!user) {
    return <span>{t('activity.system')}</span>;
  }

  return (
    <span className="font-medium">
      {user.firstName} {user.lastName}
    </span>
  );
}

/**
 * A generic function to transform versions into a list of activity events.
 * It takes a callback to compute "changes" between two versions.
 */
export function transformVersionsToActivity<T>(
  versions: Version<T>[],
  getChanges: (currentVersion: T, previousVersion: T) => { field: string; from?: unknown; to?: unknown }[] | undefined,
): ActivityItem[] {
  const activities: ActivityItem[] = [];

  // Sort versions by createdAt in descending order
  const sortedVersions = [...versions].sort(
    (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis(),
  );

  sortedVersions.forEach((version, index) => {
    const previousVersion = sortedVersions[index + 1];
    const isFirstVersion = index === sortedVersions.length - 1;

    if (isFirstVersion) {
      // Creation event
      activities.push({
        id: `${version.id}-created`,
        type: 'created',
        timestamp: version.createdAt,
        user:
          typeof version.version.createdBy === 'string'
            ? version.version.createdBy
            : version.version.createdBy?.id || null,
      });
    } else if (previousVersion) {
      const changes = getChanges(version.version, previousVersion.version) || [];
      if (changes.length > 0) {
        const userId =
          typeof version.version.lastUpdatedBy === 'string'
            ? version.version.lastUpdatedBy
            : version.version.lastUpdatedBy?.id ||
              (typeof version.version.createdBy === 'string'
                ? version.version.createdBy
                : version.version.createdBy?.id || null);

        activities.push({
          id: version.id,
          type: 'updated',
          timestamp: version.createdAt,
          user: userId,
          changes,
        });
      }
    }
  });

  return activities;
}

interface ActivityTimelineProps {
  activities: ActivityItem[];
  children: (activity: ActivityItem, index: number, total: number) => React.ReactNode;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
}

export function ActivityTimeline({
  activities,
  children,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: ActivityTimelineProps) {
  const { t } = useIntl();

  return (
    <div className="relative space-y-0">
      {activities.map((activity, index) => (
        <div key={activity.id} className="relative flex gap-3 py-3 text-sm">
          <div className="relative">
            <ActivityIcon type={activity.type} />
            {(index !== activities.length - 1 || hasNextPage) && (
              <div className="absolute left-1/2 top-6 h-full w-px -translate-x-1/2 bg-border" />
            )}
          </div>
          <div className="flex-1 mt-0.5">{children(activity, index, activities.length)}</div>
        </div>
      ))}
      {hasNextPage && (
        <div className="relative flex gap-3 py-3 text-sm">
          <div className="relative">
            <div className="flex size-6 items-center justify-center rounded-full bg-muted text-muted-foreground">
              <MoreHorizontal className="size-3.5" />
            </div>
          </div>
          <div className="flex-1 mt-0.5">
            <button
              type="button"
              onClick={() => fetchNextPage?.()}
              disabled={isFetchingNextPage}
              className="text-muted-foreground hover:text-foreground disabled:opacity-50"
            >
              {isFetchingNextPage ? t('activity.loadingMore') : t('activity.loadMore')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
