import { useTemplate } from '@/core/api';
import { useArticle } from '@/core/api';
import { SearchCombobox } from '@/shared/components/inputs';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  DatePicker,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@/shared/components/ui';
import type { Article } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

import type { ArticleDetailsFormValues } from '../types';

interface ArticleDetailsCardProps {
  control: Control<ArticleDetailsFormValues>;
  disabled?: boolean;
  article?: Article;
  disableExpiryDate?: boolean;
}

export function ArticleDetailsCard({ control, disabled, article, disableExpiryDate }: ArticleDetailsCardProps) {
  const { t } = useIntl();

  const { data: parentArticle } = useArticle(article?.parent as string, { disabled: !article?.parent });
  const { data: parentTemplate } = useTemplate(parentArticle?.template as string, { disabled: !parentArticle });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.articleDetails')}</CardTitle>
        <CardDescription>{t('articles.articleDetailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="template"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('templates.template')}</FormLabel>
                <FormControl>
                  <SearchCombobox
                    disabled={disabled || !!article}
                    value={field.value || undefined}
                    onSelect={(value) => field.onChange(value)}
                    collectionKey="templates"
                    searchKey="name"
                    texts={{
                      selectItemMsg: t('articles.assignment.selectTemplate'),
                      searchPlaceholder: t('articles.assignment.searchTemplate'),
                    }}
                    clearValue={''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {article?.parent && (
            <FormItem>
              <FormLabel>{t('contents.parentArticle')}</FormLabel>
              <FormControl>
                <Input disabled={true} value={parentTemplate?.name || ''} />
              </FormControl>
            </FormItem>
          )}
          <FormField
            control={control}
            name="expiryDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('articles.expiryDate')}</FormLabel>
                <DatePicker
                  date={field.value}
                  setDate={(date) => field.onChange(date)}
                  placeholder={t('articles.chooseExpiryDate')}
                  disabled={disableExpiryDate}
                />
                {disableExpiryDate && (
                  <FormDescription className="text-xs">
                    ({t('templates.container.containerExpiryDateDescription')})
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
