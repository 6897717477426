import { useAuth } from '@/core/auth';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { TimelineView } from '@/shared/components/ui/timeline';
import type { Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Clock, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import type { Id } from 'react-calendar-timeline';

import type { DeploymentData } from '../schema/deployment-schema';

interface DeploymentTimelineProps {
  deployments: Order['deployments'];
  startDate: string;
  endDate: string;
  onEdit?: (deployment: DeploymentData) => void;
  onDelete?: (deployment: DeploymentData) => void;
  pendingChangeRequests?: Record<string, { requestedBy: { name: string }; requestedAt: string }>;
}

export function DeploymentTimeline({
  deployments = [],
  startDate,
  endDate,
  onEdit,
  onDelete,
  pendingChangeRequests,
}: DeploymentTimelineProps) {
  const { t } = useIntl();
  const { user } = useAuth();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';

  const timelineGroups = (deployments ?? []).map((deployment) => ({
    id: deployment.id as Id,
    title: (
      <div className="mx-2 text-sm flex items-center gap-2 min-w-0">
        <span className="truncate">{deployment.title}</span>
        {pendingChangeRequests?.[deployment.id!] && (
          <Badge variant="secondary" className="gap-1 shrink-0">
            <Clock className="size-2.5" />
            <span className="text-[10px]">{t('orders.changeRequest')}</span>
          </Badge>
        )}
      </div>
    ),
  }));

  const timelineItems = (deployments ?? []).map((deployment) => ({
    id: deployment.id as Id,
    group: deployment.id as Id,
    title: (
      <div className="flex items-center gap-2 w-full justify-between">
        <span>{deployment.title}</span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="size-4 p-0 text-muted-foreground hover:text-foreground">
              <span className="sr-only">{t('navigation.openMenu')}</span>
              <MoreHorizontal className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleItemClick(deployment.id as Id);
              }}
            >
              {isJuhUser ? t('common.edit') : t('orders.requestDeploymentChange')}
            </DropdownMenuItem>
            {isJuhUser && onDelete && (
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(deployment);
                }}
              >
                {t('common.delete')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    start_time: DateTime.fromISO(deployment.startDate).toMillis(),
    end_time: DateTime.fromISO(deployment.endDate).toMillis(),
    className: pendingChangeRequests?.[deployment.id!] ? 'timeline-item-highlighted' : undefined,
  }));

  const handleItemClick = (itemId: Id) => {
    if (onEdit) {
      const deployment = deployments?.find((d) => d.id === itemId);
      if (deployment) {
        onEdit(deployment);
      }
    }
  };

  return (
    <TimelineView
      groups={timelineGroups}
      items={timelineItems}
      startTime={DateTime.fromISO(startDate).toMillis()}
      endTime={DateTime.fromISO(endDate).toMillis()}
      minZoom={24 * 60 * 60 * 1000} // 1 day
      maxZoom={7 * 24 * 60 * 60 * 1000} // 7 days
    />
  );
}
