import { z } from 'zod';

export const createOrderSchema = (
  t: (key: string) => string,
  metadataSchema: z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>,
  dynamicMetadataSchema: z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>,
) =>
  z.object({
    orderNumber: z.string().optional(),
    title: z.string().min(1, t('validation.required')),
    organization: z.string().min(1, t('validation.required')),
    object: z.string().nullable().optional(),
    startDate: z.string().min(1, t('validation.required')),
    endDate: z.string().min(1, t('validation.required')),
    description: z.string().optional(),
    metadata: metadataSchema,
    dynamicMetadataSchema: z
      .object({
        type: z.literal('object'),
        properties: z.record(
          z.object({
            type: z.enum(['string', 'number', 'boolean', 'date']),
            format: z.string().optional(),
            description: z.string(),
            minLength: z.number().optional(),
            customerAccess: z.enum(['none', 'read', 'read-and-write']),
          }),
        ),
        required: z.array(z.string()),
        propertyOrder: z.array(z.string()),
      })
      .optional(),
    dynamicMetadataValues: dynamicMetadataSchema,
  });
